import Input from "../../usable/Input";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUserPlus} from "@fortawesome/free-solid-svg-icons";
import Card from "../../usable/Card";
import React, {useState} from "react";
import UserProfileTopBar from "./../profile/UserProfileTopBar";
import {usePageContentGlobalState} from "../../context/PageContentState";
import {useNavigate} from "react-router-dom";
import {userStateContext} from "../../context/UserContentState";
import {Authentication} from "../../model/Authentication";
import PageLoadingScreen from "../PageLoadingScreen";
import UserCallPopup from "../UserCallPopup";

function UserNavbar() {

	const navigate = useNavigate();
	const { setUser, setToken } = userStateContext();
	const [ processing, setProcessing ] = useState(false);
	const { pageContentGlobalState } = usePageContentGlobalState();
	const { setPageContentGlobalState } = usePageContentGlobalState();

	const handleLogout = (e) => {
		setProcessing( true );
		Authentication.logout().then( () => {
			setUser( null );
			setToken( null );
			navigate( '/login' );
			setProcessing( false );
		}).catch( () => {
			setProcessing( false );
		});
	};

	const menuItems = [
		{ id: 'home', label: 'Home' },
		{ id: 'profile', label: 'Profile' },
		{ id: 'channel', label: 'Channels', count: 0 },
		{ id: 'notifications', label: 'Notifications', count: 10 },
		{ id: 'message', label: 'Message', count: 8 },
	];
	const handleItemClick = ( item ) => setPageContentGlobalState( item );
	
	return <>
		<UserCallPopup />
		<PageLoadingScreen show={processing} />

		{/* Mobile View */}
		<div className="
				fixed
				top-0
				left-0
				w-full
				z-10
				border-b
				border-gray-600
				overflow-hidden
				bg-[#1C2227]
				text-sm
				text-[#e5e5e5]
				h-[60px]
				md:hidden
				p-0
			">
			<UserProfileTopBar />
		</div>
		
		{/* Desktop View */}
		<Card classExt="py-5 px-6 md:flex justify-between h-[60px] text-[--text-list-items] text-[2rem] hidden">
			<ul className="flex flex-row gap-2 justify-center items-center">
				{menuItems.map((menuItem) => (
					<li
						key={menuItem.id}
						className={`custom-hover p-2 rounded relative ${pageContentGlobalState === menuItem.id ? 'active' : ''}`}
						onClick={() => handleItemClick(menuItem.id)}
					>
						{menuItem.label}
						{menuItem.count !== undefined && menuItem.count > 0 && (
							<span className="absolute top-[.1rem] right-[-.3rem] rounded-full bg-[--pills-bg] border-0 flex justify-center items-center w-[15px] h-[15px] text-[.5rem] text-white">
							  {menuItem.count}
							</span>
						)}
					</li>
				))}
			</ul>
			<ul className="flex flex-row gap-4 justify-center items-center">
				<li className={`custom-hover p-2 rounded`}>Search</li>
				<li>
					<Input type="button" classExt="w-full max-w-full rounded px-2 py-1 bg-blue-500 text-[.8rem] text-white custom-hover" label={<>Find Friends <FontAwesomeIcon icon={faUserPlus} /></>} />
				</li>
				<li className={`custom-hover p-2 rounded`} onClick={() => handleLogout()}>Logout</li>
			</ul>
		</Card>
	</>;
}

export default UserNavbar;
