import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUpload} from "@fortawesome/free-solid-svg-icons";

function UserStrangerFeatures({ info }) {

    return (
        <div className="grid grid-cols-3 grid-flow-cols gap-3">
            <div className="rounded-xl w-full h-full overflow-hidden">
                <img src="https://burst.shopifycdn.com/photos/mountain-magic-hour.jpg?width=1000&format=pjpg&exif=0&iptc=0" className="w-full w-full object-contain"  alt="Feature"/>
            </div>
            <div className="rounded-xl w-full h-full overflow-hidden bg-gray-600/20 flex justify-center items-center">
                <FontAwesomeIcon icon={faUpload} className="h-[1.5rem] w-[1.5rem] opacity-50" />
            </div>
        </div>
    );
}

export default UserStrangerFeatures;
