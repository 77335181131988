import React from 'react';

const Input = ({ label ='', type= 'button', error = '', disabled = false, classExt = '', ...rest }) => {
	return (
		type === 'submit' || type === 'button' ? (
			<button type={type} className={`${classExt? classExt : 'w-full max-w-full rounded p-2 bg-blue-500' } ${disabled ? "disabled" : ""}`} {...rest}>{label}</button>
		) : <>
			<div className="flex flex-col gap-1">
				{label && <label className="text-md text-[--text-list-items]">{label}</label>}
				<input type={type} {...rest} className={`${classExt ? classExt : 'w-full max-w-full rounded p-2 text-[#2d2d2d]'} ${disabled ? "disabled" : ""}`} />
				{error && <span className="text-red-400 text-[.8rem]">{error}</span>}
			</div>
		</>
	);
};

export default Input;
