import { ModalBlueprint } from './Modal';

document.addEventListener( 'click', ( e ) => {
	ModalBlueprint.backdrop_listener( e, 'click' );
});
document.addEventListener( 'keyup', ( e ) => {
	// eslint-disable-next-line default-case
	switch ( e.key ) {
		case "Escape":
			ModalBlueprint.backdrop_listener( e, 'keyup' );
			break;
	}
});