import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: 'https://api.peer-social.online/api',
  timeout: 5000,
  withCredentials: true,
  headers: {
    'Accept': 'application/json',
  },
});

axiosInstance.interceptors.request.use(( config ) => {
  const token = localStorage.getItem( 'ACCESS_TOKEN' );
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

axiosInstance.interceptors.response.use(null, (err) => {

  console.log( err );
  const response = JSON.parse( err.request.response ?? {} );
  const error = {
    status: err.response?.status,
    original: err,
    validation: {},
    message: null
  };

  switch ( error.status ) {
    case 422:
      for (let field in err.response.data.errors) {
        error.validation[field] = err.response.data.errors[field][0];
      }
      break;

    case 403:
      error.message = "You are not allowed to do that.";
      break;

    case 401:
      localStorage.removeItem('ACCESS_TOKEN');
      error.message = response.message;
      break;

    case 500:
      error.message = "Something went really bad. Sorry.";
      break;

    default:
      error.message = response.message;
      break;
  }

  return Promise.reject(error);
});

export default axiosInstance;