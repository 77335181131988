import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFacebook} from "@fortawesome/free-brands-svg-icons";
import Input from "../../usable/Input";
import React, {useEffect, useState} from "react";
import axios from "../../structure/Axios";
import {Modal} from "../../usable/Modal";
import {userStateContext} from "../../context/UserContentState";
import {useNavigate} from "react-router-dom";
import PageLoadingScreen from "../PageLoadingScreen";

function Facebook() {

    const navigate = useNavigate();
    const [ processing, setProcessing ] = useState(false);
    const { setUser, setToken } = userStateContext();

    const handleLogin = () => {
        window.FB.login( function( res ) {
            if ( res.authResponse ) {
                setProcessing( true );
                authenticateUser( res.authResponse.accessToken );
            }
            else {
                
                Modal.show( 'social-login-error', `<div class="px-8 py-2 bg-red-500 text-center">Apologies, this action is currently restricted to development mode.</div>` )
                    .setPosition( 'center-bottom' )
                    .setAutoClose( 2500 );

                setProcessing( false );
            }
        });
    }

    const authenticateUser = ( token ) => {
        isAuthenticated( function( isLoggedIn ) {
            if ( isLoggedIn ) {
                window.FB.api( '/me', function() {
                    registerAccount( token ).then( ( res ) => {

                        setUser( res.data.user );
                        setToken( res.data.token );
                        setProcessing( false );
                        Modal.show( 'social-login-success', `<div class="px-8 py-2 bg-green-500 text-center">${res.data.message}</div>` )
                            .setPosition( 'center-bottom' )
                            .setAutoClose( 2500 );
                        navigate( '/' );

                    }).catch( (err) => {
                        window.FB.logout();
                        Modal.show( 'social-login-error', `<div class="px-8 py-2 bg-red-500 text-center">${err.data.message}</div>` )
                            .setPosition( 'center-bottom' )
                            .setAutoClose( 2500 );
                        setProcessing( false );
                    });
                });
            }
        });
    }

    const isAuthenticated = ( callback ) => {
        window.FB.getLoginStatus(( response ) => {
            let isActive = [ 'connected', 'not_authorized' ];
            callback( isActive.includes( response.status.toLowerCase() ), response );
        });
    }

    const registerAccount = async ( token ) => {
        return new Promise(async ( resolve, reject ) => {
            await axios.post('/facebook-login', { 'token': token }).then( ( res) => {
                resolve( res );
            }).catch( (error) => {
                reject( error );
            });
        });
    }

    useEffect(() => {

        window.fbAsyncInit = () => {
            window.FB.init({
                appId: '393797723506190',
                autoLogAppEvents : true,
                cookie: true,
                xfbml: true,
                version: 'v19.0'
            });
        };

        ( function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) { return; }
            js = d.createElement(s); js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        }( document, 'script', 'facebook-jssdk' ) );

    }, []);

    return <>
        <PageLoadingScreen show={processing} />
        <Input
            type="button"
            label={<><FontAwesomeIcon icon={faFacebook}/> Facebook</>}
            classExt="w-full max-w-full rounded p-2 bg-[#f2f2f2] text-[#444444] text-[.8rem]"
            onClick={() => handleLogin()}
        />
    </>;
}

export default Facebook;