
import { HttpRequest } from "../usable/Request";
import axios from "../structure/Axios";

class Authentication {

    static sendRequestRecovery( email )
    {
        return new Promise( async ( resolve, reject ) => {
            let request = new HttpRequest.Request();
            let response = request.validate({
                rules: {
                    email: {
                        required: true,
                        email: true,
                        value: email
                    }
                },
                messages: {
                    email: {
                        required: 'Email Address is required.'
                    }
                }
            });

            if ( response.isSuccess() ) {

                await axios.get('../sanctum/csrf-cookie');
                await axios.post('/forgot-password', {
                    email: email
                }).then( ( res) => {
                    resolve( res );
                }).catch( ( error ) => {
                    reject({ 'message': error.message, ...error.validation });
                });

            }  else {

                const errors = response.getResponse();
                const temp = {};
                Object.keys( errors ).forEach( ( key ) => {
                    temp[ key ] = errors[ key ][ Object.keys( errors[ key ] )[0] ];
                });
                reject( temp );
            }
        });
    }

    static verifyResetPassword( payload )
    {
        return new Promise( async ( resolve, reject ) => {
            let request = new HttpRequest.Request();
            let response = request.validate({
                rules: {
                    email: {
                        required: true,
                        email: true,
                        value: payload.email ?? ''
                    },
                    password: {
                        required: true,
                        minLength: 6,
                        maxLength: 255,
                        value: payload.password ?? ''
                    },
                    confirm_password: {
                        required: true,
                        minLength: 6,
                        maxLength: 255,
                        value: payload.password_confirmation ?? ''
                    },
                    token: {
                        required: true,
                        value: payload.token ?? ''
                    },
                },
                messages: {
                    email: {
                        required: 'Email address is required.'
                    },
                    password: {
                        required: 'New password is required.'
                    },
                    confirm_password: {
                        required: 'Confirmation password is required.'
                    },
                    token: {
                        required: 'Something went wrong, can you please reload the page.'
                    }
                }
            });

            if ( response.isSuccess() ) {

                await axios.get('../sanctum/csrf-cookie');
                await axios.post('/reset-password', payload ).then( ( res) => {
                    resolve( res );
                }).catch( ( error ) => {
                    reject({ 'message': error.message, ...error.validation });
                });

            }  else {

                const errors = response.getResponse();
                const temp = {};
                Object.keys( errors ).forEach( ( key ) => {
                    temp[ key ] = errors[ key ][ Object.keys( errors[ key ] )[0] ];
                });
                reject( temp );
            }
        });
    }

    static verifyEmail( email, code )
    {
        return new Promise( async ( resolve, reject ) => {
            let request = new HttpRequest.Request();
            let response = request.validate({
                rules: {
                    email: {
                        required: true,
                        email: true,
                        value: email
                    },
                    code: {
                        required: true,
                        minLength: 6,
                        value: code
                    },
                },
                messages: {
                    email: {
                        required: 'Email Address is required. please refresh the page'
                    },
                    code: {
                        required: 'Please enter the verification code.'
                    }
                }
            });

            if ( response.isSuccess() ) {

                await axios.get('../sanctum/csrf-cookie');
                await axios.post('/verify-email', {
                    email: email,
                    code: code
                }).then( ( res) => {
                    resolve( res );
                }).catch( ( error ) => {
                    reject({ 'message': error.message, ...error.validation });
                });

            }  else {

                const errors = response.getResponse();
                const temp = {};
                Object.keys( errors ).forEach( ( key ) => {
                    temp[ key ] = errors[ key ][ Object.keys( errors[ key ] )[0] ];
                });
                reject( temp );
            }
        });
    }

    static login( email, password )
    {
        return new Promise( async ( resolve, reject ) => {

            let request = new HttpRequest.Request();
            let response = request.validate({
                rules: {
                    email: {
                        required: true,
                        email: true,
                        value: email
                    },
                    password: {
                        required: true,
                        minLength: 6,
                        maxLength: 255,
                        value: password
                    },
                },
                messages: {
                    email: {
                        required: 'Please enter your email address.'
                    },
                    password: {
                        required: 'Please enter your password.'
                    }
                }
            });

            if ( response.isSuccess() ) {

                await axios.get('../sanctum/csrf-cookie');
                await axios.post('/login', {
                    email: email,
                    password: password,
                    remember: false,
                }).then( ( res) => {
                    resolve( res );
                }).catch( ( error ) => {
                    reject({ 'message': error.message, ...error.validation });
                });

            } else {

                const errors = response.getResponse();
                const temp = {};
                Object.keys( errors ).forEach( ( key ) => {
                    temp[ key ] = errors[ key ][ Object.keys( errors[ key ] )[0] ];
                });
                reject( temp );
            }
        });
    }

    static register( data )
    {
        return new Promise(async ( resolve, reject ) => {

            let request = new HttpRequest.Request();
            let response = request.validate({
                rules: {
                    name: {
                        required: true,
                        maxLength: 255,
                        value: data.name ?? ''
                    },
                    email: {
                        required: true,
                        email: true,
                        value: data.email ?? ''
                    },
                    password: {
                        required: true,
                        minLength: 6,
                        maxLength: 255,
                        value: data.password ?? ''
                    },
                },
                messages: {
                    name: {
                        required: 'Please enter your full name.'
                    },
                    email: {
                        required: 'Please enter your email address.'
                    },
                    password: {
                        required: 'Please enter your password.'
                    }
                }
            });

            if ( response.isSuccess() ) {

                await axios.get( '../sanctum/csrf-cookie' );
                await axios.post( '/register', {
                    name: data.name ?? '',
                    email: data.email ?? '',
                    password: data.password ?? ''
                }).then(( res) => {
                    resolve( res );
                }).catch( (error) => {
                    reject({ 'message': error.message, ...error.validation });
                });

            } else {

                const errors = response.getResponse();
                const temp = {};
                Object.keys( errors ).forEach( ( key ) => {
                    temp[ key ] = errors[ key ][ Object.keys( errors[ key ] )[0] ];
                });
                reject( temp );
            }
        });
    }

    static logout()
    {
        return new Promise(async ( resolve, reject ) => {
            await axios.get( '../sanctum/csrf-cookie' );
            await axios.get( '/logout' ).then(() => {
                resolve( true );
            }).catch(( error ) => {
                reject( error );
            });
        });
    }
}

export { Authentication };