import Card from "../../usable/Card";
import React from "react";

function UserChatBoxSearch() {
	
	return (
		<Card classExt="flex flex-col gap-3">
			<div className="p-5 border-b custom-border flex flex-row gap-5 items-center text-[1rem]">
				<label className="text-[--text-title]">To:</label>
				<input type="text" className="bg-inherit text-[--textarea-text] w-full focus:outline-none focus:border-transparent hover:border-transparent" placeholder="Type here..." />
			</div>
			<div className="py-10 flex flex-col gap-2">
			</div>
		</Card>
	);
}

export default UserChatBoxSearch;
