import Input from "../../usable/Input";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faUserPlus, faXmark} from "@fortawesome/free-solid-svg-icons";
import React, {useEffect, useState} from "react";
import Card from "../../usable/Card";
import {Users} from "../../model/Users";
import Skeleton from "react-loading-skeleton";
import Dialog from "../../usable/Dialog";
import PageLoadingScreen from "../PageLoadingScreen";
import {Modal} from "../../usable/Modal";
import profilePic from '../../images/profile-pic.jpeg';
import {useNavigate} from "react-router-dom";
import {usePageContentGlobalState} from "../../context/PageContentState";

function UserPopularListSideBar() {

	const navigate = useNavigate();
	const { setPageContentGlobalState } = usePageContentGlobalState();
	const [ popularList, setPopularList ] = useState([]);
	const [ processing, SetProcessing ] = useState(false);
	const [ pageProcessing, SetPageProcessing ] = useState(false);
	const [ dialog, setDialog ] = useState(false);
	const [ pictureDialog, setPictureDialog ] = useState(false);
	const [ selectedImage, setSelectedImage  ] = useState('');
	const [ dialogLoaded, setDialogLoaded ] = useState(false);
	const [ userInfo, setUserInfo ] = useState({});
	const [ refresh, setRefresh ] = useState(false);

	useEffect( () =>
	{
		SetProcessing( true );
		Users.PopularList().then(( users ) => {
			setPopularList( users );
			SetProcessing( false );
		}).catch( () => SetProcessing( false ) );

	}, [ refresh ]);

	const navigateToUser = ( user_id ) => {
		navigate( `/account/id:${user_id}` );
		setPageContentGlobalState( 'stranger_profile' );
	}

	const handleOpenImage = ( url ) => {
		setSelectedImage( url );
		setPictureDialog( true );
	};

	const handleOpenDialog = ( user_id ) => {
		setDialog( true );
		const user = popularList.find( user => user.id === user_id );
		if ( user ) {
			Users.Info( user_id, [
				'name',
				'bio',
				'profile_picture',
				'total_followers'
			]).then(( user ) => {
				setDialogLoaded( true );
				setUserInfo( user );
			}).catch( () => {
				handleCloseDialog();
			});
		}
	};

	const handleFollowUser = ( user_id ) => {
		SetPageProcessing( true );
		Users.FollowUser( user_id ).then(( res ) => {
			Modal.show( 'follow-users-success', `<div class="px-8 py-2 bg-green-500 text-center">${res.message}</div>` )
				.setPosition( 'center-bottom' )
				.setAutoClose( 2500 );
			handleCloseDialog();
			SetPageProcessing( false );
			setRefresh( refresh => !refresh );
		}).catch( ( error ) => {
			Modal.show( 'follow-users-error', `<div class="px-8 py-2 bg-red-500 text-center">${error.message}</div>` )
				.setPosition( 'center-bottom' )
				.setAutoClose( 2500 );
			handleCloseDialog();
			SetPageProcessing( false );
		});
	};

	const handleCloseDialogImage = () => {
		setSelectedImage( '' );
		setPictureDialog( false );
	};
	const handleCloseDialog = () => {
		setDialog( false );
		setDialogLoaded( false );
	};

	return (
		popularList &&
		<>
			<PageLoadingScreen show={pageProcessing} />

			<Card classExt="md:flex flex-col hidden">
				<div className="px-[1rem] py-[1.2rem] flex flex-row items-center text-[1.2rem] text-[--text-title]">
					Add to your Feed
				</div>
				{ processing ? (
					<div className="border-t custom-border-list p-[.980rem] flex flex-row gap-2">
						<Skeleton circle width={44} height={44} duration={2}/>
						<div className="flex flex-col w-full gap-1 pl-[.3rem]">
							<div className="w-1/2">
								<Skeleton height={15} duration={2}/>
							</div>
							<div className="w-full">
								<Skeleton height={12} duration={2}/>
							</div>
							<div className="w-1/4 bg-[--base-color] text-gray-700 font-bold p-1 rounded-xl flex justify-center">
								<Skeleton height={6} width={20}/>
							</div>
						</div>
					</div>
				) : (
					<>
						<Dialog title='Preview Image'
								open={pictureDialog}
								onClose={() => handleCloseDialogImage()}
								transparent={true}>
								<img src={selectedImage}
									 className="min-w-[300px] max-w-[500px] min-h-[300px] max-h-[500px] object-cover object-center"
									 alt="Image"
									 loading="lazy"/>
						</Dialog>
						<Dialog title="User Profile" open={dialog}
								isLoaded={dialogLoaded}
								onClose={() => handleCloseDialog()}
								transparent={true}>
							<div className="p-3 flex flex-row gap-3 min-w-[20rem] items-center">
								<img src={userInfo.profile_picture ?? profilePic}
									 className="w-[45px] h-[45px] rounded-full object-cover border-gray-300 border-2 p-[.030rem]"
									 alt="Profile Picture"/>
								<div>
									<h1 className="font-bold text-[1rem] text-gray-950">{userInfo.name}</h1>
									<article className="text-[.8rem] text-[--text-details]"><b>{userInfo.total_followers ?? '0'}</b> Total Followers</article>
								</div>
							</div>
							<p className="text-[--highlight-color] px-3 mb-3">Are you sure you want to follow this
								user?</p>
							<div className="flex flex-row">
								<Input
									type="button"
									className="w-full py-2 bg-[#e7e7e7] text-gray-950 text-[.7rem] custom-hover font-bold border-r-2 border-gray-300"
									label={<><FontAwesomeIcon icon={faCheck}/> Yes</>}
									onClick={() => handleFollowUser(userInfo.id)}
								/>
								<Input
									type="button"
									className="w-full py-2 bg-[#e7e7e7] text-gray-950 text-[.7rem] custom-hover font-bold"
									label={<><FontAwesomeIcon icon={faXmark}/> No</>}
									onClick={() => handleCloseDialog()}
								/>
							</div>
						</Dialog>
						{popularList.map(user => (
							<div key={user.id}
								 className="border-t custom-border-list p-[1rem] flex flex-row gap-3">
								<img
									src={user.profile_picture ?? profilePic}
									alt="Profile Picture"
									className="w-[45px] h-[45px] rounded-full object-cover border-gray-300 border-2 p-[.030rem] cursor-pointer"
									onClick={() => handleOpenImage( user.profile_picture ?? profilePic )} />
								<div>
									<h1 className="font-bold text-[1rem] text-[--text-title] cursor-pointer" onClick={() => navigateToUser( user.id )}>{user.name}</h1>
									<article
										className="text-[.8rem] text-[--text-details]">{user.role ? user.role : 'Recommended profile to follow'}</article>
									<Input
										type="button"
										className="rounded-xl px-3 py-[.2rem] bg-blue-500 text-[.7rem] mt-2 custom-hover"
										label={<><FontAwesomeIcon icon={faUserPlus}/> Follow</>}
										onClick={() => handleOpenDialog( user.id )}
									/>
								</div>
							</div>
						))}
					</>
				)}
			</Card>
		</>
	);
}

export default UserPopularListSideBar;
