
function UserChannelEvents() {
	
	return (
		<div>
            User Channel Events
		</div>
	);
}

export default 
UserChannelEvents;
