
import React, {useState} from "react";
import UserChatBox from "./chat/UserChatBox";
import UserChatBoxSearch from "./chat/UserChatBoxSeach";
import UserChatList from "./chat/UserChatList";
import UserChannelList from "./chat/UserChannelList";

function UserMessageContent() {

	const [ selectedUserChatBox, setSelectedUserChatBox  ] = useState(null);

	return (
		<div className="flex gap-3 justify-between">
			<div className="w-full md:w-8/12 height-auto flex flex-col items-center">
				<div className='flex flex-col gap-3 w-full lg:w-11/12'>
					{ selectedUserChatBox ? (
						<UserChatBox account={selectedUserChatBox} />
					) : (
						<UserChatBoxSearch />
					)}
				</div>
			</div>
			<div className="w-4/12 height-auto hidden md:flex flex-col gap-3">
				<UserChatList setSelectedUser={setSelectedUserChatBox} />
				{/*<UserChannelList />*/}
			</div>
		</div>
	);
}

export default UserMessageContent;
