import React from "react";
import {faImage, faNewspaper, faSquareShareNodes, faVideo} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Card from "../../usable/Card";
import profilePic from '../../images/profile-pic.jpeg';

function UserCreatePost({ mobile = 0 }) {
	
	const GridItem = ({ icon, text, color }) => (
		<div className={`custom-hover rounded-xl flex items-center justify-center ${mobile ? 'gap-2 p-2' : 'gap-5 p-2' } font-bold`}>
			<FontAwesomeIcon icon={icon} className={ `${mobile ? `h-[1.2rem] w-[1.2rem]` : 'h-[1.5rem] w-[1.5rem]' } ${color}`} /> <span className="text-[--text-title]">{text}</span>
		</div>
	);
	
	return (
		
		mobile === 1 ? (
			
			// Mobile View
			<Card classExt="flex flex-col gap-4 p-[.7rem] mt-[60px] md:hidden">
				<textarea className="w-full max-w-full rounded-[2rem] bg-inherit border items-center px-4 py-[.7rem] h-[45px]" placeholder="What's on your mind?"></textarea>
				<div className="grid grid-cols-4 grid-flow-cols gap-3 pb-0 text-[.7rem]">
					<GridItem icon={faImage} text="Photo" color="text-blue-400" />
					<GridItem icon={faVideo} text="Video" color="text-green-400" />
					<GridItem icon={faSquareShareNodes} text="Stream" color="text-violet-400" />
					<GridItem icon={faNewspaper} text="Article" color="text-orange-400" />
				</div>
			</Card>
			
		) : (
			
			 // Desktop View
			<Card classExt="md:flex flex-col gap-4 px-7 py-5 pb-3 hidden">
				<div className="flex flex-row gap-4">
					<img src={profilePic}
						 className="w-[60px] h-[60px] rounded-full object-cover border-gray-300 border-2 p-[.030rem]" alt="Thumbnail" />
					<textarea className="w-full max-w-full rounded-[2rem] bg-inherit border custom-textarea-border text-[--textarea-text] items-center px-4 py-[1.2rem] h-[60px]" placeholder="Start a post"></textarea>
				</div>
				<div className="grid grid-cols-4 grid-flow-cols gap-3 px-4 text-[.7rem]">
					<GridItem icon={faImage} text="Photo" color="text-blue-400" />
					<GridItem icon={faVideo} text="Video" color="text-green-400" />
					<GridItem icon={faSquareShareNodes} text="Stream" color="text-violet-400" />
					<GridItem icon={faNewspaper} text="Write Article" color="text-orange-400" />
				</div>
			</Card>
		)
	);
}

export default UserCreatePost;
