import React, {useEffect, useState} from "react";
import {useSocket} from "../context/SocketContextState";
import Dialog from "../usable/Dialog";
import profilePic from "../images/profile-pic.jpeg";
import Input from "../usable/Input";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPhone, faTimes} from "@fortawesome/free-solid-svg-icons";
import {userStateContext} from "../context/UserContentState";
import {openPopup} from "../usable/Global";

function UserCallPopup() {

    const socket = useSocket();
    const { user } = userStateContext();
    const [ callDialog, setCallDialog ] = useState(false);
    const [ userCall, setUserCall ] = useState(null);

    useEffect(() => {
        const handle_received_call = (data) => {
            setCallDialog( true );
            setUserCall( data );
        };

        socket.on( 'received_request_call', handle_received_call );
        return () => {
            socket.off( 'received_request_call', handle_received_call );
        };
    }, [ socket ]);

    useEffect(() => {

        const received_leave_call = (data) => {
            if ( data.total_remaining <= 1 ) {
                socket.emit( 'end_request_call', {
                    'room': data.room,
                    'my_id': user.id
                });
                setUserCall(null);
                setCallDialog( false );
            }
        };

        socket.on( 'received_decline_request_call', received_leave_call );
        return () => {
            socket.off( 'received_decline_request_call', received_leave_call );
        };

    }, [socket, user.id, userCall]);

    const handleAcceptCall = () => {
        socket.emit( 'accept_request_call', {
            'room': userCall.room,
            'my_id': user.id,
            'name': user.name,
            'picture': user.profile_picture ?? null
        });
        setCallDialog( false );
        openPopup( `../groupcall/ROOM:${userCall.room}`, 900, 650 );
    };

    const declineCall = () => {
        socket.emit( 'end_request_call', {
            'room': userCall.room,
            'my_id': user.id
        });
        setUserCall(null);
        setCallDialog( false );
    };

    if ( !userCall )
        return null;

    return (
        <Dialog title="Call Confirmation"
                open={callDialog}
                onClose={() => declineCall()}
                transparent={true}>
            <div className='relative flex items-center justify-center flex-col gap-3 px-[3rem] p-5'>
                <img
                    src={userCall.picture ? userCall.picture : profilePic}
                    className="w-[200px] h-[200px] p-[.030rem] rounded-full object-cover border-gray-300 border-2"
                    alt="Thumbnail"/>
                <div className='w-full text-center'>
                    <h1 className='text-gray-950 font-bold text-[1.3rem]'>{userCall.name}</h1>
                    <p className='font-bold text-[#1e88e5]'>Incoming Call</p>
                </div>
            </div>
            <div className="flex flex-row h-[2.5rem]">
                <Input
                    type="button"
                    className="w-full h-full bg-[#66bb6a] text-white text-[.8rem] custom-hover font-bold border-r-2 border-gray-300"
                    label={<><FontAwesomeIcon icon={faPhone}/> &nbsp;Accept</>}
                    onClick={() => handleAcceptCall( userCall.from )}
                />
                <Input
                    type="button"
                    className="w-full h-full bg-[#ef5350] text-white text-[.8rem] custom-hover font-bold"
                    label={<><FontAwesomeIcon icon={faTimes}/> Decline</>}
                    onClick={() => declineCall()}
                />
            </div>
        </Dialog>
    );
}

export default UserCallPopup;