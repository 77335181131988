
import Card from "../../usable/Card";
import React, {useState} from "react";
import coverPhoto from "../../images/cover-photo.jpeg";
import profilePic from "../../images/profile-pic.jpeg";
import '../../CoverLoader.css';
import '../../Loader.css';
import Skeleton from "react-loading-skeleton";
import Dialog from "../../usable/Dialog";

function UserStrangerBanner({ info, processing }) {

    const [ pictureDialog, setPictureDialog ] = useState(false);
    const [ selectedImage, setSelectedImage  ] = useState('');

    const handleOpenImage = ( url ) => {
        setSelectedImage( url );
        setPictureDialog( true );
    };
    const handleCloseDialogImage = () => {
        setSelectedImage( '' );
        setPictureDialog( false );
    };

    return <>
        <Dialog title='Preview Image'
                open={pictureDialog}
                onClose={() => handleCloseDialogImage()}
                transparent={true}>
            <img src={selectedImage}
                 className="min-w-[300px] max-w-[800px] min-h-[300px] max-h-[500px] object-cover object-center"
                 alt="Image"
                 loading="lazy"/>
        </Dialog>
        <Card classExt={`flex flex-col`}>
            {processing ? (
                <div className="relative">
                    <img
                        src={coverPhoto}
                        className="
                            h-[180px]
                            w-full
                            object-cover
                            cursor-pointer
                            opacity-10
                        "
                        alt="Cover"
                    />
                    <div className="img-loader z-10 center-absolute">
                        <div className="loading-circle"></div>
                        <div className="loading-circle"></div>
                        <div className="loading-circle"></div>
                        <div className="loading-shadow"></div>
                        <div className="loading-shadow"></div>
                        <div className="loading-shadow"></div>
                    </div>
                </div>
            ) : (
                <img src={info.cover_photo ? info.cover_photo : coverPhoto}
                     className="
                        h-[180px]
                        w-full
                        z-5
                        object-cover
                        cursor-pointer
                        transition-opacity
                        duration-500
                        hover:opacity-60
                        hidden
                        md:block
                     "
                     alt="Cover"
                     loading="lazy"
                     onClick={() => handleOpenImage( info.cover_photo ? info.cover_photo : coverPhoto )}/>
            )}
            <div className='flex flex-row gap-7 px-8 pb-3'>
                {processing ? (
                    <div className='relative'>
                        <img src={profilePic}
                             className="
								w-[130px]
								h-[130px]
								p-[.030rem]
								md:mt-[-2.4rem]
								rounded-full
								border-gray-300
								border-2
								object-cover
                                cursor-pointer
                                opacity-50
							 "
                             alt="Thumbnail"/>
                        <div className='center-absolute md:mt-[-1.2rem]'>
                            <div className="img-loading-circle w-[130px] h-[130px] p-[.030rem]">
                            </div>
                        </div>
                    </div>
                ) : (
                    <img src={info.profile_picture ? info.profile_picture : profilePic}
                         className="
								w-[130px]
								h-[130px]
								p-[.030rem]
								md:mt-[-2.4rem]
								rounded-full
								object-cover
								border-gray-300
								border-2
								z-10
								cursor-pointer
								transition-opacity
								duration-500
								hover:opacity-90
							 "
                            alt="Thumbnail"
                            loading="lazy"
                            onClick={() => handleOpenImage( info.profile_picture ? info.profile_picture : profilePic )}/>
                )}
                <div className='flex flex-col gap-2 py-5'>
                    {processing ? (
                        <div className='flex flex-col gap-1 w-full'>
                            <Skeleton height={25} width={200} duration={2}/>
                            <Skeleton height={15} width={300} duration={2}/>
                        </div>
                    ) : (
                        <>
                            <h1 className="text-[1.5rem] text-[--text-title]">{info.name}</h1>
                            <p className="text-[.9rem] text-[--text-details]">{info.bio}</p>
                        </>
                    )}
                </div>
            </div>
        </Card>
    </>;
}

export default UserStrangerBanner;
