import axios from "../structure/Axios";

class Status {

    static async getGenders() {
        return new Promise( async ( resolve, reject ) => {
            await axios.get( '../sanctum/csrf-cookie' );
            await axios.get( '/get-genders' ).then( ( res) => {
                resolve( res.data );
            }).catch( ( error ) => {
                console.error( 'Error fetching popular list:', error );
                reject( error );
            });
        });
    }

    static async getRelationshipStatus() {
        return new Promise( async ( resolve, reject ) => {
            await axios.get( '../sanctum/csrf-cookie' );
            await axios.get( '/get-relationship-status' ).then( ( res) => {
                resolve( res.data );
            }).catch( ( error ) => {
                console.error( 'Error fetching popular list:', error );
                reject( error );
            });
        });
    }
}

export { Status };