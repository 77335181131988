
import Home from "../page/Home";
import Login from "../page/Login";
import Register from "../page/Register";
import GroupCall from "../page/GroupCall";
import EmailVerify from "../page/EmailVerify";
import { PageContentStateProvider } from "../context/PageContentState";
import NotFound from "../page/NotFound";
import ForgotPassword from "../page/ForgotPassword";
import ResetPassword from "../page/ResetPassword";

export const Navigation = [
    {
        path: '/login',
        name: "Login",
        element: <Login />,
        isPrivate: false
    },
    {
        path: '/register',
        name: "Register",
        element: <Register />,
        isPrivate: false
    },
    {
        path: '/',
        name: "",
        element: <PageContentStateProvider>
                    <Home />
                </PageContentStateProvider>,
        isPrivate: true
    },
    {
        path: '/verify-email/:email',
        name: "Email Verification",
        element: <EmailVerify />,
        isPrivate: true
    },
    {
        path: '/groupcall/:room',
        name: "",
        element: <GroupCall />,
        isPrivate: true
    },
    {
        path: "/forgot-password",
        name: "Forgot Password",
        element: <ForgotPassword />,
        isPrivate: false,
    },
    {
        path: "/reset-password/:token",
        name: "Account Recovery",
        element: <ResetPassword />,
        isPrivate: false,
    },
    {
        path: '/account/:id',
        name: "",
        element: <PageContentStateProvider>
                    <Home />
                </PageContentStateProvider>,
        isPrivate: true
    },
    {
        path: '*',
        name: "404 Page",
        element: <NotFound />,
        isPrivate: false
    }
];