import axios from "../structure/Axios";

class Call {

    static async StartCall( user_ids ) {
        return new Promise( async ( resolve, reject ) => {
            await axios.get('../sanctum/csrf-cookie');
            await axios.post( `/call-group`, {
                'users_id': user_ids
            }).then( (res) => {
                resolve( res.data );
            }).catch((err) => {
                reject({ 'message': err.message, ...err.validation });
            });
        });
    }
}

export { Call };