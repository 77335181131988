
function 
UserChannelMembers() {
	
	return (
		<div>
            User Channel Members
		</div>
	);
}

export default 
UserChannelMembers;
