import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {UserContextProvider} from "./context/UserContentState";
import {GoogleOAuthProvider} from "@react-oauth/google";
import {SocketProvider} from "./context/SocketContextState";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <GoogleOAuthProvider clientId="836349665577-jfpb5285lrbpotplld2u0srsi1lbomj4.apps.googleusercontent.com">
      {/*<React.StrictMode>*/}
          <SocketProvider>
              <UserContextProvider>
                <App />
              </UserContextProvider>
          </SocketProvider>
      {/*</React.StrictMode>*/}
    </GoogleOAuthProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
