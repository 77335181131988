import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookmark, faCoins } from "@fortawesome/free-solid-svg-icons";
import Card from "../../usable/Card";
import React, {useEffect, useState} from "react";
import {userStateContext} from "../../context/UserContentState";
import axios from "../../structure/Axios";
import coverPhoto from '../../images/cover-photo.jpeg';
import profilePic from '../../images/profile-pic.jpeg';
import Dialog from "../../usable/Dialog";

function UserProfileSideBar() {

	const { user, setUser } = userStateContext();
	const [ pictureDialog, setPictureDialog ] = useState(false);
	const [ selectedImage, setSelectedImage  ] = useState('');

	useEffect( () => {
		
		axios.get('/user' ).then( ( user ) => {
			setUser( user.data );
		}).catch( ( error ) => {
			console.log( error );
		});

	}, []);

	const ProfileList = ({ icon, text, color }) => (
		<div className="
				flex
				flex-row
				items-center
				gap-4
				p-[.7rem]
				border-t
				custom-border-list
				text-[1rem]
				custom-hover
			">
			<div className="w-[.8rem]">
				<FontAwesomeIcon icon={icon} className={`text-[1rem] ${color}`} />
			</div>
			<span className="text-[--text-list-items]">{text}</span>
		</div>
	);

	const handleOpenImage = ( url ) => {
		setSelectedImage( url );
		setPictureDialog( true );
	};
	const handleCloseDialogImage = () => {
		setSelectedImage( '' );
		setPictureDialog( false );
	};
	
	return (
		<>
			<Dialog title='Preview Image'
					open={pictureDialog}
					onClose={() => handleCloseDialogImage()}
					transparent={true}>
				<img src={selectedImage}
					 className="max-h-[500px] object-fill object-center"
					 alt="Image"
					 loading="lazy"/>
			</Dialog>
			<Card classExt="md:flex flex-col gap-3 hidden">
				<div className="flex flex-col items-center">
					<img src={`${ user.cover_photo ? user.cover_photo : coverPhoto }`}
						 className="
								h-[120px]
								w-full
								z-5
								object-cover
								cursor-pointer
								transition-opacity
								duration-500
								hover:opacity-60
								hidden
								md:block
							 "
						 alt="Cover"
						 loading="lazy"
						 onClick={() => handleOpenImage( user.cover_photo ? user.cover_photo : coverPhoto )}/>
					<img src={`${ user.profile_picture ? user.profile_picture : profilePic }`}
						 className="
								w-[120px]
								h-[120px]
								p-[.030rem]
								md:mt-[-4rem]
								rounded-full
								object-cover
								border-gray-300
								border-2
								z-10
								cursor-pointer
								transition-opacity
								duration-500
								hover:opacity-90
							 "
						 alt="Thumbnail"
						 loading="lazy"
						 onClick={() => handleOpenImage( user.profile_picture ? user.profile_picture : profilePic )}/>
				</div>
				<div className="flex flex-col items-center text-center justify-center gap-2">
					<h1 className="text-[1.5rem] text-[--text-title]">{user.name}</h1>
					<p className="text-[.9rem] max-w-[80%] text-[--text-details]">{user.bio}</p>
				</div>
				<div className="flex flex-col pt-4">
					<ProfileList icon={faCoins} color="text-yellow-500" text="0" />
					<ProfileList icon={faBookmark} color="text-gray-500" text="My Items" />
				</div>
			</Card>
		</>
	);
}

export default UserProfileSideBar;
