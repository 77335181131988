import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGoogle} from "@fortawesome/free-brands-svg-icons";
import Input from "../../usable/Input";
import {googleLogout, useGoogleLogin} from "@react-oauth/google";
import React, {useState} from "react";
import PageLoadingScreen from "../PageLoadingScreen";
import axios from "../../structure/Axios";
import {Modal} from "../../usable/Modal";
import {useNavigate} from "react-router-dom";
import {userStateContext} from "../../context/UserContentState";

function Google() {

    const navigate = useNavigate();
    const { setUser, setToken } = userStateContext();
    const [ processing, setProcessing ] = useState(false);

    const login = useGoogleLogin({

        onSuccess: async ( codeResponse ) => {
            setProcessing( true );

            if ( codeResponse.access_token ) {

                await axios.post('/google-login', { 'token': codeResponse.access_token }).then( ( res) => {

                    setUser( res.data.user );
                    setToken( res.data.token );
                    setProcessing( false );

                    Modal.show( 'social-login-success', `<div class="px-8 py-2 bg-green-500 text-center">${res.data.message}</div>` )
                        .setPosition( 'center-bottom' )
                        .setAutoClose( 2500 );
                    navigate( '/' );

                }).catch(( err ) => {

                    googleLogout();
                    Modal.show( 'social-login-error', `<div class="px-8 py-2 bg-red-500 text-center">${err.data.message}</div>` )
                        .setPosition( 'center-bottom' )
                        .setAutoClose( 2500 );
                });
                setProcessing( false );

            } else {

                Modal.show( 'social-login-error', `<div class="px-8 py-2 bg-red-500 text-center">Apologies, this action is currently restricted to development mode.</div>` )
                    .setPosition( 'center-bottom' )
                    .setAutoClose( 2500 );
                setProcessing( false );
            }
        },

        onError: ( error ) => console.log( 'Login Failed:', error )
    });

    return <>
        <PageLoadingScreen show={processing} />
        <Input
            type="button"
            label={<><FontAwesomeIcon icon={faGoogle} /> Google</>}
            classExt="w-full max-w-full rounded p-2 bg-[#f2f2f2] text-[#444444] text-[.8rem]"
            onClick={() => login()}
        />
    </>;
}

export default Google;
