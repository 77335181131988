
const HttpRequest = {

    Request: class {

        validate( config ) {

            // Reset
            HttpRequest.Blueprint.response = {};

            if ( !HttpRequest.Blueprint.doesKeyExist( config, 'rules' ) ) {
                HttpRequest.Blueprint.setResponseValue( 'request', 'error', "Rules must be applied." );
                return this;
            }

            const rules = config.rules;
            const messages = config.messages ?? {};

            for ( const input in rules ) {

                const attr = rules[ input ];
                for ( const key in attr ) {

                    if ( !HttpRequest.Blueprint.doesKeyExist( attr, 'value' ) ) {
                        HttpRequest.Blueprint.setResponseValue( input, 'value', "Value attribute is required." );
                        continue;
                    }

                    let error = false;
                    let value = attr[ 'value' ] ?? '';
                    let message = HttpRequest.Blueprint.getMessage( messages, input, key, attr[ key ] ?? '' );

                    if ( !attr[ key ] )
                        continue;

                    if ( key === 'value' )
                        continue;

                    if ( key !== 'required' ) {
                        if ( value.trim() === '' )
                            continue;
                    }

                    // eslint-disable-next-line default-case
                    switch ( key ) {
                        case 'required':
                            if ( value.trim() === '' )
                                error = true;
                            break;
                        case 'email':
                            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                            if ( !emailRegex.test( value ) )
                                error = true;
                            break;
                        case 'url':
                            const urlRegex = /^(?:\w+:)?\/\/([^\s.]+\.\S{2}|localhost[:?\d]*)\S*$/;
                            if ( !urlRegex.test( value ) )
                                error = true;
                            break;
                        case 'date':
                            const date = new Date( value );
                            if ( isNaN( date ) )
                                error = true;
                            break;
                        case 'dateISO':
                            const isoDateRegex = /^\d{4}-\d{2}-\d{2}$/;
                            if ( !isoDateRegex.test( value ) )
                                error = true;
                            else {
                                const date = new Date(value);
                                if ( !( isNaN( date ) || value !== date.toISOString().slice( 0, 10 ) ) )
                                    error = true;
                            }
                            break;
                        case 'number':
                            if ( isNaN( parseFloat( value ) ) )
                                error = true;
                            break;
                        case 'digits':
                            const digitsRegex = /^\d+$/;
                            if ( !digitsRegex.test( value ) )
                                error = true;
                            break;
                        case 'creditCard':
                            const cleanedValue = value.replace( /\D/g, '' );
                            if ( !/^\d+$/.test( cleanedValue ) )
                                error = true;
                            else if ( cleanedValue.length < 13 || cleanedValue.length > 19 )
                                error = true;
                            else {
                                let sum = 0;
                                let isEven = false;
                                for ( let i = cleanedValue.length - 1; i >= 0; i-- ) {
                                    let digit = parseInt( cleanedValue.charAt( i ), 10 );
                                    if ( isEven ) {
                                        digit *= 2;
                                        if ( digit > 9 ) {
                                            digit -= 9;
                                        }
                                    }
                                    sum += digit;
                                    isEven = !isEven;
                                }

                                if ( sum % 10 !== 0 )
                                    error = true;
                            }
                            break;
                        case 'equalTo':
                            if ( value !== attr[ key ] )
                                error = true;
                            break;
                        case 'maxLength':
                            if ( value.length > attr[ key ] )
                                error = true;
                            break;
                        case 'minLength':
                            if ( value.length < attr[ key ] )
                                error = true;
                            break;
                        case 'max':
                            // Not applicable for now
                            break;
                        case 'min':
                            // Not applicable for now
                            break;
                        case 'accept':
                            // Not applicable for now
                            break;
                    }

                    if ( error )
                        HttpRequest.Blueprint.setResponseValue( input, key, message );
                }
            }

            return this;
        }


        isSuccess() {
            if ( Object.keys( HttpRequest.Blueprint.response ).length === 0 )
                return true;

            return false;
        }


        isError() {
            if ( Object.keys( HttpRequest.Blueprint.response ).length )
                return true;

            return false;
        }


        getResponse() {
            return HttpRequest.Blueprint.response;
        }
    },

    Blueprint: class {

        static response = {};
        static messages = {
            required: 'This field is required.',
            email: 'Please enter a valid email address.',
            url: 'Please enter a valid URL.',
            date: 'Please enter a valid date.',
            dateISO: 'Please enter a valid date (ISO format).',
            number: 'Please enter a valid number.',
            digits: 'Please enter only digits.',
            creditCard: 'Please enter a valid credit card number.',
            equalTo: 'Please enter the same value again.',
            accept: 'Please enter a value with a valid extension.',
            maxLength: 'Please enter no more than [value] characters.',
            minLength: 'Please enter at least [value] characters.',
            max: 'Please enter a value less than or equal to [value].',
            min: 'Please enter a value greater than or equal to [value].'
        };
        static defaultValue = {
            maxLength: 265,
            minLength: 6
        };

        static getMessage( messages, input, key, value = '' ) {

            if ( messages && this.doesKeyExist( messages, input ) ) {
                const messageAttr = messages[ input ];
                if ( this.doesKeyExist( messageAttr, key ) ) {
                    return this.messageFormat( messageAttr[ key ], value );
                }
            }

            if ( !this.doesKeyExist( this.messages, key ) )
                return '';

            if ( !value ) {
                // eslint-disable-next-line default-case
                switch ( input ) {
                    case 'maxLength':
                        return this.messageFormat( this.messages[ key ], this.defaultValue[ 'maxLength' ] );
                    case 'minLength':
                        return this.messageFormat( this.messages[ key ], this.defaultValue[ 'minLength' ] );
                }
            }

            return this.messageFormat( this.messages[ key ], value );
        }

        static setResponseValue( input, key, message ) {
            if ( !this.response[ input ] ) {
                this.response[ input ] = {};
            }
            this.response[ input ][ key ] = message;
        }

        static messageFormat( message, replace = '' ) {
            if ( replace )
                return message.replace( /\[value]/g, replace );

            return message;
        }

        static doesKeyExist( obj, key ) {
            return obj.hasOwnProperty( key );
        }
    }
};

export { HttpRequest };