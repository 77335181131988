import UserCreatePost from "./home/UserCreatePost";
import UserPost from "./home/UserPost";
import UserPopularListSideBar from "./home/UserPopularListSideBar";
import React from "react";

function UserHomeContent() {
	
	return (
		<div className="flex gap-3">
			<div className="w-full md:w-8/12 height-auto flex flex-col items-center">
				<div className='flex flex-col gap-3 w-full lg:w-11/12'>
					<UserCreatePost/>
					<UserPost />
				</div>
			</div>
			<div className="w-4/12 height-auto pb-1 hidden md:flex flex-col gap-3">
				<UserPopularListSideBar />
			</div>
		</div>
	);
}

export default UserHomeContent;
