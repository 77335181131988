import {Modal} from './Modal';

let show = ( id, html ) => Modal.show( id, html );
let hide = ( id ) => Modal.hide( id );
let sleep = ( ms ) => new Promise(resolve => setTimeout( resolve, ms ) );
let openPopup = ( url, width, height ) => {
    const left = window.innerWidth / 2 - width / 2;
    const top = window.innerHeight / 2 - height / 2;
    const popup = window.open(
      url,
      '_blank',
      `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${width}, height=${height}, top=${top}, left=${left}`
    );
  
    if ( popup )
      popup.focus();
    else
      alert( 'Please allow pop-ups for this site' );

    return popup;
};

const currentDate = () => {
    const currentDate = new Date();
    return currentDate.toISOString().slice(0, 19).replace('T', ' ');
};

const formatTime = ( dateString ) => {
    const date = new Date( dateString );
    const now = new Date();
    const yesterday = new Date( now );
    yesterday.setDate( now.getDate() - 1 );

    if ( date.toDateString() === now.toDateString() ) {
        // Today
        return date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
    } else if (date.toDateString() === yesterday.toDateString()) {
        // Yesterday
        return `${date.getMonth() + 1}/${date.getDate()} ${date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}`;
    } else if (date.getFullYear() === now.getFullYear()) {
        // Same year
        return `${date.getMonth() + 1}/${date.getDate()} ${date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}`;
    } else {
        // Different year
        return `${date.getFullYear()}/${date.getMonth() + 1}-${date.getDate()} ${date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}`;
    }
}

function htmlentities(str) {
    return str.replace(/[\u00A0-\u9999<>\&]/gim, function(i) {
        return '&#'+i.charCodeAt(0)+';';
    });
}


export { show, hide, sleep, openPopup, formatTime, currentDate, htmlentities };