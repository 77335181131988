import UserPost from "./home/UserPost";
import UserNotificationList from "./notification/UserNotificationList";
import React from "react";

function UserNotificationsContent() {
	
	return (
		<div className="flex gap-3">
			<div className="w-full md:w-8/12 height-auto flex flex-col gap-3 items-center">
				<div className='flex flex-col gap-3 w-full lg:w-11/12'>
					<UserPost/>
				</div>
			</div>
			<div className="w-4/12 height-auto pb-1 hidden md:flex flex-col gap-3">
				<UserNotificationList />
			</div>
		</div>
	);
}

export default UserNotificationsContent;
