
import React, { createContext, useContext, useState } from 'react';

const ChannelNavbarContentState = createContext();
export const useChannelNavbarGlobalState = () => useContext( ChannelNavbarContentState );
export const ChannelNavbarContentStateProvider = ({ children }) =>
{
	const [ChannelNavbarGlobalState, setChannelNavbarGlobalState] = useState( '' );
	return (
		<ChannelNavbarContentState.Provider value={{ ChannelNavbarGlobalState, setChannelNavbarGlobalState }}>
			{children}
		</ChannelNavbarContentState.Provider>
	);
};
