import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars, faGlobe, faHeartPulse, faStore} from "@fortawesome/free-solid-svg-icons";
import Card from "../usable/Card";
import React, {useState} from "react";
import {usePageContentGlobalState} from "../context/PageContentState";

// faBaseball, faMedal, faVideo

function UserSocialsSideBar() {
	const { pageContentGlobalState } = usePageContentGlobalState();
	const { setPageContentGlobalState } = usePageContentGlobalState();
	const [ isOpen, setIsOpen ] = useState( false );
	const toggleMenu = () => setIsOpen( !isOpen );
	const SocialList = ({ icon, text, content }) => (
		<div className={`
				flex
				flex-row
				items-center
				gap-4
				p-[.7rem]
				border-t
				custom-border-list
				text-[.9rem]
				custom-hover
				${pageContentGlobalState === content && 'active' }
			`}
			onClick={() => setPageContentGlobalState( content )}
			>
			<div className="w-[.8rem]">
				<FontAwesomeIcon icon={icon} className="text-[1rem] text-[#8aa2c5]" />
			</div>
			<span className="text-[--text-list-items]">{text}</span>
		</div>
	);
	
	return (
		<Card classExt="flex flex-col">
			<div className="
					flex
					flex-row
					justify-between
					items-center
					text-[1rem]
					text-[--text-title]
					p-3
					md:px-[.9rem]
					md:py-[1.2rem]
					md:text-[1.5rem]
				">
				Social Events
				<FontAwesomeIcon
					icon={faBars}
					onClick={toggleMenu}
					className="
						h-[1.5rem]
						w-[1.5rem]
						p-1
						rounded
						custom-hover
						md:hidden
					"/>
			</div>
			<div className={`flex flex-col ${isOpen ? 'h-auto' : 'h-0' } md:h-auto`}>
				<SocialList icon={faGlobe} text="Global Chat" content="globalChat" />
				<SocialList icon={faHeartPulse} text="Dating" content="dating" />
				<SocialList icon={faStore} text="Marketplace" content="marketplace" />
				{/* <SocialList icon={faBaseball} text="Live Sports" /> */}
				{/* <SocialList icon={faVideo} text="Streaming Online" /> */}
				{/* <SocialList icon={faMedal} text="Play Online Games" /> */}
			</div>
		</Card>
	);
}

export default UserSocialsSideBar;
