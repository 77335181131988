import React, { useState } from 'react';

function PageThemes() {
    const [isHovered, setIsHovered] = useState(false);
    const [themeMode, setThemeMode] = useState('dark');

    const handleThemeChange = (mode) => {
        setThemeMode( mode );
        document.body.classList.remove('dark', 'light', 'sky');
        document.body.classList.add(mode);
    };

    return (
        <>
            <div
                className="
                    fixed 
                    top-[14.5%] 
                    right-0 
                    h-[5.1rem]
                    min-w-[32px]
                    z-[1]
                    hover:w-[10rem]"
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                <div
                    className={`
                        fixed
                        top-[15%] 
                        z-[1]
                        px-2
                        py-3
                        text-black
                        bg-slate-300 
                        w-[9rem]
                        h-[5.5rem]
                        rounded-md
                        transition-all 
                        duration-[.3s] 
                        text-center
                        ${isHovered ? 'right-4' : 'right-[-150px]'}
                    `}
                >
                    <h1 className="font-semibold text-[#4c4c4c]">Theme Mode</h1>
                    <div className='flex flex-row gap-2 w-full items-center justify-center mt-2'>
                        <div
                            className={`bg-[--dark-card] theme rounded px-2 text-white cursor-pointer ${themeMode === 'dark' && 'active'}`}
                            onClick={() => handleThemeChange('dark')}
                        >
                            D
                        </div>
                        <div
                            className={`bg-[--light-card] theme rounded px-2 cursor-pointer ${themeMode === 'light' && 'active'}`}
                            onClick={() => handleThemeChange('light')}
                        >
                            L
                        </div>
                        <div
                            className={`bg-[--sky-card] theme rounded px-2 cursor-pointer ${themeMode === 'sky' && 'active'}`}
                            onClick={() => handleThemeChange('sky')}
                        >
                            S
                        </div>
                    </div>
                </div>
                <div
                    className={`
                        z-[1]
                        fixed
                        top-[15%]
                        px-[.200rem]
                        py-3
                        rounded-tl-md 
                        rounded-bl-md 
                        uppercase 
                        writing-mode-vertical-rl 
                        text-center 
                        select-none 
                        break-normal
                        bg-slate-300 
                        text-[#191919]
                        text-[.6rem]
                        font-semibold
                        tracking-[.11em]
                        transition-all 
                        duration-300
                        ${isHovered ? 'right-[-20px]' : 'right-0'}
                    `}
                >
                    Themes
                </div>
            </div>
        </>
    );
}

export default PageThemes;
