import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPen, faPlus} from "@fortawesome/free-solid-svg-icons";
import Input from "../../usable/Input";
import React from "react";
import {usePageContentGlobalState} from "../../context/PageContentState";

function UserProfileMainButtons() {

    const { setPageContentGlobalState } = usePageContentGlobalState();

    return (
        <div className="flex flex-row gap-2">
            <Input
                type="button"
                className="rounded px-8 py-2 bg-[#5c6676] text-[.9rem] text-white custom-hover"
                label={<><FontAwesomeIcon icon={faPen} /> Edit Profile</>}
                onClick={() => setPageContentGlobalState( 'setting' )}
            />
            <Input type="button"
                   classExt="rounded px-8 py-2 bg-[#5c6676] text-[.9rem] text-white custom-hover"
                   label={<><FontAwesomeIcon icon={faPlus} /> Add Reels</>}
            />
        </div>
    );
}

export default UserProfileMainButtons;
