import Card from "../../usable/Card";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import React, {useEffect, useState} from "react";
import {Users} from "../../model/Users";
import profilePic from '../../images/profile-pic.jpeg';
import Dialog from "../../usable/Dialog";
import {currentDate} from "../../usable/Global";
import Skeleton from "react-loading-skeleton";
import {useSocket} from "../../context/SocketContextState";
import axios from "../../structure/Axios";
import {Chat} from "../../model/Chat";

function UserChatList({ setSelectedUser }) {

	const socket = useSocket();
	const [ processing, SetProcessing ] = useState(false);
	const [ connectedUsers, setConnectedUsers ] = useState([]);
	const [ pictureDialog, setPictureDialog ] = useState(false);
	const [ selectedImage, setSelectedImage  ] = useState('');
	const [ selectedAccount, setSelectedAccount ] = useState(null);

	useEffect( () =>
	{
		SetProcessing( true );
		Users.ConnectionList().then(( users ) => {
			SetProcessing( false );
			setConnectedUsers( users );
		}).catch( () => SetProcessing( false ) );

	}, []);

	const handleOpenImage = ( url ) => {
		setSelectedImage( url );
		setPictureDialog( true );
	};

	const handleCloseDialogImage = () => {
		setSelectedImage( '' );
		setPictureDialog( false );
	};

	const setSelectedAccountChat = ( user ) => {
		setSelectedAccount( user );
		setSelectedUser(user);
	};

	useEffect(() => {

		const handleSeenMessages = ( payload ) => {
			console.log( payload );
			if ( payload.type ) {
				Chat.UpdateSeenMessage( payload ).then(() => {
					// console.log( 'let inform the other user that we have seen the message...' );
				});
			}
		};

		const handleReceivedMessage = (data) => {
			setConnectedUsers(prevUsers => {
				return prevUsers.map(user => {
					if (data.from === user.id) {
						if ( selectedAccount && selectedAccount.id === data.from ) {
							handleSeenMessages({
								'from': data.from,
								'type': true
							});
						}
						return {...user, recent_message: data.message};
					}
					return user;
				});
			});
		};

		socket.on('received_chat_message', handleReceivedMessage);
		return () => {
			socket.off('received_chat_message', handleReceivedMessage);
		};
	}, [selectedAccount, socket]);


	return <>
		<Dialog title='Preview Image'
				open={pictureDialog}
				onClose={() => handleCloseDialogImage()}
				transparent={true}>
			<img src={selectedImage}
				 className="min-w-[300px] max-w-[500px] min-h-[300px] max-h-[500px] object-cover object-center"
				 alt="Image"
				 loading="lazy"/>
		</Dialog>
		<Card classExt="flex flex-col gap-3">
			<h1 className="text-[1.5rem] text-[--text-title] p-5 pb-1">Chats</h1>
			<div className="relative flex flex-row px-5 text-black ">
				<FontAwesomeIcon icon={faMagnifyingGlass} className="absolute top-[.6rem] left-8 text-[--textarea-text]" />
				<input type="text" className="w-full rounded-xl p-[.4rem] bg-[--textarea-bg] pl-8 focus:outline-none focus:border-transparent hover:border-transparent" placeholder="Search here..." />
			</div>
			<div className="flex flex-col">
				{
					processing ? (
						<div className="border-t custom-border-list p-[.980rem] flex flex-row gap-2">
							<Skeleton circle width={44} height={44} duration={2}/>
							<div className="flex flex-col w-full gap-1 pl-[.3rem]">
								<div className="w-1/2">
									<Skeleton height={15} duration={2}/>
								</div>
								<div className="w-full">
									<Skeleton height={12} duration={2}/>
								</div>
							</div>
						</div>
						) : (
						connectedUsers && <>
							{ connectedUsers.map( user => (
								<div className="p-3 flex flex-row justify-between gap-3 custom-hover" key={user.id} onClick={() => setSelectedAccountChat(user)}>
									<div className="flex flex-row gap-3 whitespace-nowrap overflow-hidden overflow-ellipsis">
										<img src={ user.profile_picture ?? profilePic }
											 className="w-[20px] h-[20px] md:w-[45px] md:h-[45px] rounded-full object-cover border-gray-300 border-2 p-[.030rem]"
											 alt="Cover"
											 onClick={() => handleOpenImage( user.profile_picture ?? profilePic )}/>
										<div className="flex flex-col gap-1 pt-0">
											<h1 className="text-[1rem] text-[--text-article] font-semibold">{ user.name }</h1>
											<p className="text-[--text-details]">{ user.recent_message }</p>
										</div>
									</div>
									<div className="flex flex-row gap-2 text-[#ececec] pr-2">
										<small className="font-bold text-[#a3a3a3] flex gap-1">N/A</small>
									</div>
								</div>
							))}
						</>
					)}
			</div>
		</Card>
	</>;
}

export default UserChatList;
