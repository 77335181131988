import Card from "../usable/Card";
import React, {useEffect, useRef, useState} from "react";
import Input from "../usable/Input";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCloud, faUpload} from "@fortawesome/free-solid-svg-icons";
import Dialog from "../usable/Dialog";
import PageLoadingScreen from "./PageLoadingScreen";
import {Users} from "../model/Users";
import {Modal} from "../usable/Modal";
import {Status} from "../model/Status";
import {userStateContext} from "../context/UserContentState";
import coverPhoto from '../images/cover-photo.jpeg';
import profilePic from '../images/profile-pic.jpeg';
import Skeleton from "react-loading-skeleton";

function UserSettingContent() {

    const { user } = userStateContext();
    const fileCoverInputRef = useRef( null );
    const fileProfilePicInputRef = useRef( null );
    const formRef = useRef(null);

    const [ genderLists, setGenders ] = useState({});
    const [ relationshipStatuses, setRelationshipStatuses ] = useState({});

    const [ dialog1, setDialog1 ] = useState(false);
    const [ dialog2, setDialog2 ] = useState(false);
    const [ pageProcessing, setPageProcessing ] = useState(true);
    const [ processing, setProcessing ] = useState(false);
    const [ selectedCoverImage, setSelectedCoverImage ] = useState(null);
    const [ selectedProfilePicture, setSelectedProfilePicture ] = useState(null);

    const [ errors, setErrors ] = useState({});
    const [ name, setName ] = useState('');
    const [ relationship, setSetRelationship ] = useState('');
    const [ gender, setGender ] = useState('');
    const [ dateBirth, setDateBirth ] = useState('');
    const [ role, setRole ] = useState('');
    const [ bio, setBio ] = useState('');

    useEffect(() => {

        Status.getGenders().then((data) => {
            setGenders( data );
        });
        Status.getRelationshipStatus().then((data) => {
            setRelationshipStatuses( data );
        });
        Users.Info( user.id, [
            'name',
            'role',
            'bio',
            'gender_id',
            'relationship_status_id',
            'profile_picture',
            'cover_photo',
            'date_birth'
        ]).then( (data) => {
            setBio( data.bio ?? '' );
            setRole( data.role ?? '' );
            setDateBirth( data.date_birth ?? '' );
            setName( data.name ?? '' );
            setGender( data.gender_id ?? 1 );
            setSetRelationship( data.relationship_status_id ?? 1 );
            setPageProcessing( false );
        });

    }, [ user ]);

    const handleUploadProfilePicture = () => {
        fileProfilePicInputRef.current.click();
    };
    const handleUploadCover = () => {
        fileCoverInputRef.current.click();
    };

    const handleProfilePictureChange = (e) => {
        const file = e.target.files[0];
        if ( file )
            setSelectedProfilePicture( file );
        else
            setSelectedProfilePicture( null );
    };
    const handleCoverImageChange = (e) => {
        const file = e.target.files[0];
        if ( file )
            setSelectedCoverImage( file );
        else
            setSelectedCoverImage( null );
    };

    const displayImage = ( file ) => {
        return URL.createObjectURL(file);
    };
    const handleCloseDialog = () => {
        setDialog1( false );
        setDialog2( false );
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setProcessing( true );

        let formattedDateBirth = dateBirth;
        if ( dateBirth ) {
            let dateObject = new Date(dateBirth);
            formattedDateBirth = dateObject.toISOString().split('T')[0];
        }
        let payload = {
            'name': name,
            'relationship': relationship,
            'gender': gender,
            'date_birth': formattedDateBirth,
            'role': role,
            'bio': bio
        };

        if ( selectedCoverImage )
            payload[ 'cover_photo' ] = selectedCoverImage;

        if ( selectedProfilePicture )
            payload[ 'profile_photo' ] = selectedProfilePicture;

        Users.UpdateProfileInfo( payload ).then( (res) => {

            formRef.current.reset();
            setErrors({});
            setProcessing( false );
            setSelectedProfilePicture( null );
            setSelectedCoverImage( null );

            Modal.show( 'update-profile-success', `<div class="px-8 py-2 bg-green-500 text-center">${res.message}</div>` )
                .setPosition( 'center-bottom' )
                .setAutoClose( 2500 );

        }).catch( (err) => {

            setErrors( err );
            setProcessing( false );

            if ( err?.cover_photo !== undefined || err?.profile_photo !== undefined ) {
                let pic_error = err.cover_photo ? err.cover_photo : err.profile_photo;
                Modal.show( 'update-profile-error', `<div class="px-8 py-2 bg-red-500 text-center">${pic_error}</div>` )
                    .setPosition( 'center-bottom' )
                    .setAutoClose( 2500 );
            }
            else {
                if ( err.message ) {
                    Modal.show( 'update-profile-error', `<div class="px-8 py-2 bg-red-500 text-center">${err.message}</div>` )
                        .setPosition( 'center-bottom' )
                        .setAutoClose( 2500 );
                }
            }
        });
    };

    return (
        <>
            <PageLoadingScreen show={processing} />
            <Dialog title="Cover Photo" open={dialog1} onClose={handleCloseDialog} transparent={true}>
                <div className="flex flex-col gap-5 px-5 py-5">
                    <img src={selectedCoverImage ? displayImage( selectedCoverImage ) : coverPhoto}
                         className="rounded w-[500px] h-[150px] object-cover" alt='profile-cover'/>
                    {selectedCoverImage && <label className="text-gray-950 font-bold">{selectedCoverImage.name}</label>}
                    <Input type="button"
                           label={<>Upload Picture</>}
                           classExt="w-auto rounded px-5 py-2 bg-blue-500 text-[.8rem] text-white custom-hover font-bold"
                           onClick={handleUploadCover}
                    />
                    <input
                        ref={fileCoverInputRef}
                        type='file'
                        className='hidden'
                        accept='image/*'
                        onChange={handleCoverImageChange}
                    />
                </div>
            </Dialog>
            <Dialog title="Profile Piture" open={dialog2} onClose={handleCloseDialog} transparent={true}>
                <div className="flex flex-col justify-center items-center gap-5 px-10 py-5">
                    <img
                        src={selectedProfilePicture ? displayImage( selectedProfilePicture ) : profilePic}
                        className=" w-[200px] h-[200px] p-[.030rem] rounded-full object-cover border-gray-300 border-2"
                        alt="Thumbnail"/>
                    {selectedProfilePicture &&
                        <label className="text-gray-950 font-bold">{selectedProfilePicture.name}</label>}
                    <Input type="button"
                           label={<>Upload Picture</>}
                           classExt="w-full rounded px-5 py-2 bg-blue-500 text-[.8rem] text-[--text-list-items] custom-hover font-bold"
                           onClick={handleUploadProfilePicture}
                    />
                    <input
                        ref={fileProfilePicInputRef}
                        type='file'
                        className='hidden'
                        accept='image/*'
                        onChange={handleProfilePictureChange}
                    />
                </div>
            </Dialog>
            <form ref={formRef} onSubmit={handleSubmit}>
                <div className="flex ">
                    <Card classExt='w-8/12 px-10 pt-4 pb-10'>
                        <div className='flex flex-row items-center justify-between'>
                            <h1 className='text-[--text-title] text-[1.2rem] font-bold py-4 m-0'>EDIT PROFILE</h1>
                            <div className="flex flex-row gap-3">
                                <Input type="button"
                                       label={<>Cover Photo &nbsp;<FontAwesomeIcon icon={faUpload}/></>}
                                       classExt="w-auto rounded px-5 py-2 bg-[#5c6676] text-[.8rem] text-white custom-hover font-bold"
                                       onClick={() => setDialog1(true)}
                                />
                                <Input type="button"
                                       label={<>Profile Photo &nbsp;<FontAwesomeIcon icon={faUpload}/></>}
                                       classExt="w-auto rounded px-5 py-2 bg-[#5c6676] text-[.8rem] text-white custom-hover font-bold"
                                       onClick={() => setDialog2(true)}
                                />
                            </div>
                        </div>
                        <hr className="mt-1"/>
                        { pageProcessing  ? (
                            <>
                                <div className="flex flex-row gap-8 py-5 w-full">
                                    <div className="w-full">
                                        <label>
                                            Full Name:
                                            <Skeleton width={'100%'} height={40} duration={3}/>
                                        </label>
                                    </div>
                                    <div className="w-full">
                                        <label>
                                            Relationship Status:
                                            <Skeleton width={'100%'} height={40} duration={3}/>
                                        </label>
                                    </div>
                                </div>
                                <div className='flex gap-8 py-5 w-full'>
                                    <div className="flex flex-row w-6/12 gap-4">
                                        <label className="flex flex-col gap-1 w-full text-[--text-list-items]">
                                            <label>
                                                Gender
                                                <Skeleton width={'100%'} height={40} duration={3}/>
                                            </label>
                                        </label>
                                        <div className="w-full">
                                            <label>
                                                Date of birther
                                                <Skeleton width={'100%'} height={40} duration={3}/>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="w-6/12">
                                        <label>
                                            Tell us about yourself: (e.g: Role)
                                            <Skeleton width={'100%'} height={40} duration={3}/>
                                        </label>
                                    </div>
                                </div>
                                <label className="flex flex-col gap-1 text-[--text-list-items]">
                                    <label>
                                        Bio
                                        <Skeleton width={'100%'} height={40} duration={5}/>
                                    </label>
                                </label>
                                <Skeleton width={105} height={40} duration={3} className='mt-8'/>
                            </>
                        ) : (
                            <>
                                <div className="flex flex-row gap-8 py-5 w-full">
                                    <div className="w-full">
                                        <Input label='Full Name:'
                                               type="text"
                                               classExt="w-full rounded p-2 border custom-textarea-border text-gray-950"
                                               placeholder="First Name, Middle Name, Last Name, Ext Name"
                                               value={name}
                                               disabled={processing}
                                               onChange={(e) => setName(e.target.value)}
                                               error={errors.name}
                                        />
                                    </div>
                                    <div className="w-full">
                                        <label className="flex flex-col gap-1 text-[--text-list-items]">
                                            Relationship Status
                                            <select
                                                className="w-full rounded p-2 border custom-textarea-border text-gray-950"
                                                value={relationship}
                                                onChange={(e) => setSetRelationship(e.target.value)}>
                                                {Object.entries(relationshipStatuses).map(([value, label]) => (
                                                    <option key={value} value={value}>{label}</option>
                                                ))}
                                            </select>
                                        </label>
                                    </div>
                                </div>
                                <div className='flex gap-8 py-5 w-full'>
                                    <div className="flex flex-row w-6/12 gap-4">
                                        <label className="flex flex-col gap-1 w-full text-[--text-list-items]">
                                            Gender
                                            <select
                                                className="w-full rounded p-2 border custom-textarea-border text-gray-950"
                                                value={gender}
                                                onChange={(e) => setGender(e.target.value)}>
                                                {Object.entries(genderLists).map(([value, label]) => (
                                                    <option key={value} value={value}>{label}</option>
                                                ))}
                                            </select>
                                        </label>
                                        <div className="w-full">
                                            <label className="flex flex-col gap-1 w-full text-[--text-list-items]">
                                                Date of birth
                                                <DatePicker
                                                    selected={dateBirth}
                                                    onChange={(date) => setDateBirth(date)}
                                                    dateFormat="yyyy-MM-dd"
                                                    isClearable
                                                    showYearDropdown
                                                    scrollableYearDropdown
                                                    yearDropdownItemNumber={15}
                                                    placeholderText="yyyy-mm-dd"
                                                    className="w-full rounded p-[.465rem] border custom-textarea-border text-gray-950"
                                                />
                                            </label>
                                        </div>
                                    </div>
                                    <div className="w-6/12">
                                        <Input label='Tell us about yourself: (e.g: Role)'
                                               type="text"
                                               classExt="w-full rounded p-2 border custom-textarea-border text-gray-950"
                                               placeholder="Max characters 50"
                                               maxLength='50'
                                               disabled={processing}
                                               onChange={(e) => setRole(e.target.value)}
                                               error={errors.role}
                                               value={role}
                                        />
                                    </div>
                                </div>
                                <label className="flex flex-col gap-1 text-[--text-list-items]">
                                    Bio
                                    <textarea
                                        className="border custom-textarea-border text-gray-950 w-full rounded p-2"
                                        cols="4"
                                        placeholder="Your Bio..."
                                        maxLength='500'
                                        value={bio}
                                        onChange={(e) => setBio(e.target.value)}
                                    />
                                </label>
                                <Input type="submit"
                                       label={<>Submit <FontAwesomeIcon icon={faCloud}/></>}
                                       classExt="w-auto rounded px-5 py-2 bg-blue-500 text-[.8rem] text-white custom-hover mt-8 font-bold"
                                />
                            </>
                        )}
                    </Card>
                </div>
            </form>
        </>
    );
}

export default UserSettingContent;
