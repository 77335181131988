import React, {useState} from "react";
import Card from "../../usable/Card";

function UserStrangerMenu({ info }) {

    const [ selectedItem, setSelectedItem ] = useState( 'posts' );

    const handleItemClick = ( item ) => {
        setSelectedItem( item );
    };

    return <>
        <Card classExt="p-2 px-5 py-4 text-[2rem]">
            <ul className="flex flex-row gap-3 items-center text-[--text-list-items]">
                <li className={`custom-hover p-2 rounded  ${selectedItem === 'posts' ? 'active' : ''}`}
                    onClick={() => handleItemClick('posts')}>Posts
                </li>
                <li className={`custom-hover p-2 rounded  ${selectedItem === 'about' ? 'active' : ''}`}
                    onClick={() => handleItemClick('about')}>About
                </li>
                <li className={`custom-hover p-2 rounded  ${selectedItem === 'photos' ? 'active' : ''}`}
                    onClick={() => handleItemClick('photos')}>Photos
                </li>
                <li className={`custom-hover p-2 rounded  ${selectedItem === 'videos' ? 'active' : ''}`}
                    onClick={() => handleItemClick('videos')}>Videos
                </li>
                <li className={`custom-hover p-2 rounded  ${selectedItem === 'channel' ? 'active' : ''}`}
                    onClick={() => handleItemClick('channel')}>Channels
                </li>
                <li className={`custom-hover p-2 rounded  ${selectedItem === 'marketplace' ? 'active' : ''}`}
                    onClick={() => handleItemClick('marketplace')}>Marketplace
                </li>
            </ul>
        </Card>
    </>;
}

export default UserStrangerMenu;
