
function UserChannelMedia() {
	
	return (
		<div>
            User Channel Media
		</div>
	);
}

export default 
UserChannelMedia;
