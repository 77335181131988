import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRightFromBracket, faDisplay, faEllipsis, faMicrophone, faVideo} from "@fortawesome/free-solid-svg-icons";
import Card from "../usable/Card";
import {useParams} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import {userStateContext} from "../context/UserContentState";
import {useSocket} from "../context/SocketContextState";
import profilePic from "../images/profile-pic.jpeg";

function GroupCall() {

    const socket = useSocket();
    const { user } = userStateContext();
    let { room } = useParams();
    const peer_ref = useRef(null);
    const localStream = useRef();
    const remoteStream = useRef();
    room = room.replace( 'ROOM:', '' );

    const [ isMounted, setMount ] = useState(false);

    const create_offer = () => {
        return new Promise(( resolve, reject) => {
            peer_ref.current.createOffer().then( offer => {
                return peer_ref.current.setLocalDescription( offer );
            }).then( () => {
                resolve( peer_ref.current.localDescription );
            }).catch( error => {
                console.error( 'Error local creating offer: ', error );
                reject( error );
            });
        })
    };

    const create_answer = ( data ) => {
        return new Promise(( resolve, reject) => {
            peer_ref.current.setRemoteDescription( data.remote_description ).then(() => {
                return peer_ref.current.createAnswer();
            }).then( ( answer ) => {
                return peer_ref.current.setLocalDescription( answer );
            }).then( () => {
                resolve( peer_ref.current.localDescription );
            }).catch( ( error ) => {
                console.error( 'Error answering the offer description: ', error );
                reject( error );
            });
        });
    }

    const establish_remote_description = (data) => {
        return new Promise( (resolve, reject) => {
            peer_ref.current.setRemoteDescription( data.remote_description ).then(() => {
                resolve( true );
            }).catch(error => {
                console.error( 'Error answered offer description: ', error );
                reject( error );
            });
        });
    }

    const get_user_media = async () => {
        try {
            return await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
        } catch (error) {
            console.error('Error accessing media devices:', error);
        }
    }

    useEffect(() => {

        if ( !isMounted && socket ) {

            get_user_media().then( (stream) => {

                let isRemoteStreamSet = false;
                peer_ref.current = new RTCPeerConnection();

                peer_ref.current.ontrack = (event) => {
                    if ( !isRemoteStreamSet && event.streams && event.streams.length > 0 ) {
                        const remoteVideo = remoteStream.current;
                        remoteVideo.srcObject = event.streams[0];
                        remoteVideo.onloadedmetadata = () => {
                            remoteVideo.play().catch((error) => {
                                console.error('Failed to start video playback:', error);
                            });
                        };
                        isRemoteStreamSet = true;
                    }
                };

                peer_ref.current.onicecandidate = (event) => {
                    if ( event.candidate ) {
                        socket.emit( 'send_icecandidate', {
                            'from': user.id,
                            'room': room,
                            'candidate': event.candidate
                        });
                    }
                };

                if ( stream ) {

                    localStream.current.srcObject = stream;
                    localStream.current.play();

                    stream.getTracks().forEach(track => {
                        peer_ref.current.addTrack( track, stream );
                    });

                    create_offer().then(( local_description ) =>  {
                        socket.emit( 'register_user', user.id );
                        socket.emit( 'register_user_room_call', {
                            'user_id': user.id,
                            'room': room,
                            'local_description': local_description
                        });
                    });
                }
            });

            socket.on( 'received_icecandidate', ( data ) => {
                peer_ref.current.addIceCandidate( data.candidate ).catch( error => {
                    console.error( 'Error adding ICE candidate: ', error );
                });
            });

            socket.on( 'received_request_call_answer', ( data ) => {
                if ( data.from !== user.id ) {
                    create_answer( data ).then(( local_description ) => {
                        console.log( 'offer: ', data );
                        socket.emit( 'send_answered_call', {
                            'from': user.id,
                            'to_user_id': data.from,
                            'local_description': local_description
                        });
                    });
                }
            });

            socket.on( 'received_answered_call', ( data ) => {
                if ( data.from !== user.id ) {
                    establish_remote_description( data ).then( () => {
                        console.log( 'accept: ', data );
                    });
                }
            });

            setMount( true );

            return () => {
                socket.off( 'request_call_answer', create_answer );
                socket.off( 'received_answered_call', establish_remote_description );
            };
        }
    }, [isMounted, room, socket, user.id]);

    return (
        <div className="relative flex flex-col h-screen">
            <div className="flex flex-row justify-between p-4 px-7">
                <div className="flex flex-row gap-3">
                    <img
                        src={user.profile_picture ?? profilePic}
                        className="w-[40px] h-[40px] md:w-[50px] md:h-[50px] rounded-full object-cover border-gray-300 border-2 p-[.030rem]"
                        alt="Cover"
                    />
                    <div className="flex flex-col gap-1 pt-0">
                    </div>
                </div>
                <div className="flex flex-row gap-3">
                    <FontAwesomeIcon
                        icon={faEllipsis}
                        className="font-extrabold p-1 rounded h-[1.5rem] w-[1.5rem] custom-hover"
                    />
                </div>
            </div>
            <video ref={remoteStream} autoPlay playsInline className='w-full border max-h[300px]'/>
            <div className="w-full flex flex-row justify-center items-center gap-3 p-10">
                <FontAwesomeIcon icon={faDisplay} className="custom-hover h-[1.5rem] w-[1.5rem] p-2 rounded-xl"
                                 title="Share your screen"/>
                <FontAwesomeIcon icon={faVideo} className="custom-hover h-[1.5rem] w-[1.5rem] p-2 rounded-xl"
                                 title="Turn off video"/>
                <FontAwesomeIcon icon={faMicrophone}
                                 className="custom-hover h-[1.5rem] w-[1.5rem] p-2 rounded-xl"
                                 title="Mute microphone"/>
                <FontAwesomeIcon icon={faArrowRightFromBracket}
                                 className="custom-hover h-[1.5rem] w-[1.5rem] p-2 rounded-xl bg-red-800"
                                 title="End call"/>
            </div>
            <Card
                className="fixed right-[3rem] top-[4rem] h-[25%] w-[25%] rounded-xl bg-gray-800 overflow-hidden">
                <video ref={localStream} autoPlay playsInline className='w-full h-full'/>
            </Card>
        </div>
    );
}

export default GroupCall;
