import Card from "../../usable/Card";
import {faNewspaper, faPeopleGroup, faUserGroup} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function UserChannelActivities() {
	
    const ListItem = ({ icon, text, description = "" }) => {
		return (
			<li className="flex flex-row gap-3">
                <FontAwesomeIcon icon={icon} className="text-[--text-article] h-[1rem] w-[1rem]" />
                <div className="flex flex-col mt-[-.2rem]">
                    <span className="text-[1rem] text-[--text-article]">{text}</span> 
                    <small className="text-[--text-details] opacity-80 text-[.8rem]">{description}</small>
                </div>
			</li>
		);
	}

	return (
		<Card>
			<h1 className="text-[1.8rem] text-[--text-title] p-[1rem] pb-0">Activities</h1>
			<ul className="mt-5 flex flex-col gap-5 border-t custom-border-list p-[1rem]">
				<ListItem icon={faNewspaper} text="No new posts today" description="No posts in the last month." />
				<ListItem icon={faUserGroup} text="7 total members" description="No new members in the last week" />
				<ListItem icon={faPeopleGroup} text="Created 5 years ago"/>
			</ul>
		</Card>
	);
}

export default UserChannelActivities;
