import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBug, faMessage, faUserMinus, faUserPlus} from "@fortawesome/free-solid-svg-icons";
import Input from "../../usable/Input";
import React, {useEffect, useState} from "react";
import {usePageContentGlobalState} from "../../context/PageContentState";
import {Users} from "../../model/Users";
import {Modal} from "../../usable/Modal";
import PageLoadingScreen from "../PageLoadingScreen";

function UserStrangerMainButtons({ info }) {

    const { setPageContentGlobalState } = usePageContentGlobalState();
    const [ followed, SetFollowed ] = useState(false);
    const [ pageProcessing, SetPageProcessing ] = useState(false);

    useEffect( () => {
        if ( info && info.id ) {
            Users.IsFollowed(info.id).then((res) => {
                const status = res.data.status;
                SetFollowed( status );
            });
        }
    }, [ info ]);

    const handleFollowUser = ( id ) =>
    {
        SetPageProcessing( true );
        Users.FollowUser( id ).then(( res ) => {

            Modal.show( 'follow-users-success', `<div class="px-8 py-2 bg-green-500 text-center">${res.message}</div>` )
                .setPosition( 'center-bottom' )
                .setAutoClose( 2500 );

            SetFollowed( true );
            SetPageProcessing( false );

        }).catch( ( error ) => {

            Modal.show( 'follow-users-error', `<div class="px-8 py-2 bg-red-500 text-center">${error.message}</div>` )
                .setPosition( 'center-bottom' )
                .setAutoClose( 2500 );

            SetPageProcessing( false );
        });
    }

    const handleUnFollowUser = ( id ) =>
    {
        SetPageProcessing( true );
        Users.UnFollowUser( id ).then(( res ) => {

            Modal.show( 'follow-users-success', `<div class="px-8 py-2 bg-green-500 text-center">${res.message}</div>` )
                .setPosition( 'center-bottom' )
                .setAutoClose( 2500 );

            SetFollowed( false );
            SetPageProcessing( false );

        }).catch( ( error ) => {

            Modal.show( 'follow-users-error', `<div class="px-8 py-2 bg-red-500 text-center">${error.message}</div>` )
                .setPosition( 'center-bottom' )
                .setAutoClose( 2500 );

            SetPageProcessing( false );
        });
    }

    if ( !info )
        return null;

    return (
        <>
            <PageLoadingScreen show={pageProcessing}/>
            <div className="flex flex-row gap-2">
                { followed ? (
                    <Input
                        type="button"
                        className="rounded px-6 py-2 bg-green-800 text-[.8rem] text-white custom-hover"
                        label={ <><FontAwesomeIcon icon={faUserMinus}/> Unfollow </>}
                        onClick={() => handleUnFollowUser( info.id )}
                    />
                ) : (
                    <Input
                        type="button"
                        className="rounded px-6 py-2 bg-green-800 text-[.8rem] text-white custom-hover"
                        label={  <><FontAwesomeIcon icon={faUserPlus}/> Follow </> }
                        onClick={() => handleFollowUser( info.id )}
                    />
                )}
                <Input type="button"
                       classExt="rounded px-6 py-2 bg-blue-800 text-[.8rem] text-white custom-hover"
                       label={<><FontAwesomeIcon icon={faMessage}/> Send Message</>}
                />
                <Input type="button"
                       classExt="rounded px-6 py-2 bg-red-800 text-[.8rem] text-white custom-hover"
                       label={<><FontAwesomeIcon icon={faBug}/></>}
                />
            </div>
        </>
    );
}

export default UserStrangerMainButtons;
