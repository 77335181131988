import React, { useState } from "react";
import Card from "../../usable/Card";

function UserNotificationList() {
  const [activeIndex, setActiveIndex] = useState(null);

  const notificationList = [
    {
      name: "Robroy Canales",
      message: "Highlighted for you to checkout.",
      time: "1h",
    },
    {
      name: "Bonifacio Canales",
      message: "Reacted to your in PHP.",
      time: "Yesterday",
    },
  ];

  const handleNotificationClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <Card classExt="flex flex-col gap-3">
      <h1 className="text-[1.5rem] text-[--text-title] p-4 pb-2">Notifications</h1>
      <div className="flex flex-col border-t border-gray-400">
        {notificationList.map((notification, index) => (
          <div
            key={index}
            className={`p-3 flex flex-row justify-between gap-3 custom-hover ${index === activeIndex ? 'active' : ''}`}
            onClick={() => handleNotificationClick(index)}
          >
            <div className="flex flex-row gap-3 whitespace-nowrap overflow-hidden overflow-ellipsis">
              <img
                src="https://i.pinimg.com/736x/de/59/4e/de594ec09881da3fa66d98384a3c72ff.jpg"
                className="w-[20px] h-[20px] md:w-[45px] md:h-[45px] rounded-full object-cover border-gray-300 border-2 p-[.030rem]"
                alt="Cover"
              />
              <div className="flex flex-col gap-1 pt-0">
                <h1 className="text-[1rem] text-[--text-article] font-semibold">{notification.name}</h1>
                <p className="text-[--text-details]">{notification.message}</p>
              </div>
            </div>
            <div className="flex flex-row gap-2 pr-2">
              <small className="font-bold text-[--text-article] flex gap-1">{notification.time}</small>
            </div>
          </div>
        ))}
      </div>
    </Card>
  );
}

export default UserNotificationList;
