
import ChatBox from "./ChatBox";
import Members from "./Members";
import ServerList from "./ServerList";

function GlobalChatContainer() {

	return (
		<div className="flex gap-3">
			<div className="w-full md:w-9/12 height-auto flex flex-col gap-3">
                <ChatBox />
			</div>
			<div className="w-3/12 height-auto hidden md:flex flex-col gap-3">
				<ServerList />
				<Members />
			</div>
		</div>
	);
}

export default GlobalChatContainer;
