
function UserChannelFiles() {
	
	return (
		<div>
            User Channel Files
		</div>
	);
}

export default 
UserChannelFiles;
