import Card from "../../../usable/Card";
import {
	faCirclePlus,
	faEllipsis,
	faFaceSmile,
	faImage,
	faThumbsUp
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";

function ChatBox() {

	return <>
		<Card classExt="flex flex-col">
			<div className="p-4 flex flex-row justify-between gap-3 border-b custom-border">
                <h1 className="text-[1rem] text-[--text-title] font-semibold p-0 m-0 uppercase">Philippines</h1>
				<div className="flex flex-row items-center gap-2 text-[--text-title] pr-2">
					<FontAwesomeIcon icon={faEllipsis} className="font-extrabold p-1 rounded h-[1.1rem] w-[1.1rem] custom-hover" />
				</div>
			</div>
			<div className="flex flex-col gap-2 max-h-[25rem]">
				<div className="flex flex-col items-start">
                    <div className="flex flex-row gap-2 w-full border-b custom-border-list px-3 py-2">
						<img src="https://i.pinimg.com/736x/de/59/4e/de594ec09881da3fa66d98384a3c72ff.jpg"
							 className="w-[31px] h-[31px] rounded-full object-cover border-gray-300 border-2 p-[.030rem] mt-[.150rem]" alt="Cover" />
						<div className="flex flex-col pt-0">
							<h1 className="text-[.8rem] text-[--text-title] font-semibold p-0 m-0">Robroy Canales</h1>
							<p className="text-[.7rem] text-[--text-details] p-0 m-0 mt-[-.3rem]">Student</p>
                            <p className="font-semibold text-[--text-article] text-[.8rem] mt-1">
                                Hi There!
                            </p>
						</div>
					</div>
                    <div className="flex flex-row gap-2 w-full border-b border-gray-300 px-3 py-2">
						<img src="https://i.pinimg.com/736x/de/59/4e/de594ec09881da3fa66d98384a3c72ff.jpg"
							 className="w-[31px] h-[31px] rounded-full object-cover border-gray-300 border-2 p-[.030rem] mt-[.150rem]" alt="Cover" />
						<div className="flex flex-col pt-0">
							<h1 className="text-[.8rem] text-[--text-title] font-semibold p-0 m-0">Bonifacio Canales</h1>
							<p className="text-[.7rem] text-[--text-details] p-0 m-0 mt-[-.3rem]">Student</p>
                            <p className="font-semibold text-[--text-article] text-[.8rem] mt-1">
                                Nice Game!
                            </p>
						</div>
					</div>
				</div>
			</div>
			<div className="p-3 border-t custom-border-list flex flex-row gap-3 items-center text-[--text-list-items]">
				<div className="flex flex-row gap-1">
					<FontAwesomeIcon icon={faCirclePlus} className="h-[1.2rem] w-[1.2rem] custom-hover p-1 rounded" />
					<FontAwesomeIcon icon={faImage} className="h-[1.2rem] w-[1.2rem] custom-hover p-1 rounded" />
				</div>
				<input type="text" className="rounded-xl px-3 py-[.3rem] w-full bg-[--textarea-bg] text-[--textarea-text] focus:outline-none focus:border-transparent hover:border-transparent" placeholder="Your message here..."/>
				<div className="flex flex-row gap-1">
					<FontAwesomeIcon icon={faFaceSmile} className="h-[1.2rem] w-[1.2rem] custom-hover p-1 rounded" />
					<FontAwesomeIcon icon={faThumbsUp} className="h-[1.2rem] w-[1.2rem] custom-hover p-1 rounded" />
				</div>
			</div>
		</Card>
	</>;
}

export default ChatBox;
