import Card from "../../usable/Card";
import {currentDate, formatTime, htmlentities, openPopup} from '../../usable/Global';
import {
	faCirclePlus,
	faEllipsis,
	faFaceSmile,
	faImage, faPhone,
	faThumbsUp, faVideo
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useEffect, useRef, useState} from "react";
import {Users} from "../../model/Users";
import profilePic from '../../images/profile-pic.jpeg';
import '../../Loader2.css';
import '../../Loader3.css';
import {useSocket} from "../../context/SocketContextState";
import {Modal} from "../../usable/Modal";
import {userStateContext} from "../../context/UserContentState";
import {Chat} from "../../model/Chat";
import {Call} from "../../model/Call";

function UserChatBox({ account }) {

	const socket = useSocket();
	const { user } = userStateContext();
	const messagesEndRef = useRef(null);
	const [ processing, setProcessing ] = useState(false);
	const [ messages, setMessages ] = useState([]);
	const [ seen, setSeen ] = useState(null);
	const [ isTyping, setIsTyping ] = useState(false);

	const scrollToBottom = () => {
		messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
	};

	useEffect(() => {
		scrollToBottom();
	}, [ messages ]);

	useEffect(() => {
		const handleUserTyping = (data) => {
			if ( data.from === account.id )
				setIsTyping( data.typing );
			else
				setIsTyping( false );
		};

		socket.on( 'received_is_typing', handleUserTyping );
		return () => {
			socket.off( 'received_is_typing', handleUserTyping );
		};
	}, [ account.id, socket]);

	useEffect(() => {
		const handleSeenMessage = (data) => {
			if ( data.from === account.id && seen ) {
				let updated_seen = messages.length + 1;
				setSeen(prevSeen => ({
					...prevSeen,
					receiver: ( data.last !== prevSeen.receiver ) ? updated_seen : prevSeen.receiver
				}));
			}
		};
		const handleReceivedMessage = (data) => {
			if ( data.from === account.id ) {
				Users.IsConnected( data.from ).then(() => {
					setMessages(prevMessages => {
						const newMessages = [...prevMessages, {
							'type': 'receiver',
							'message': data.message,
							'date_sent': currentDate()
						}];
						setSeen(prevSeen => ({
							...prevSeen,
							receiver: newMessages.length + 1,
							sender: newMessages.length + 1
						}));
						console.log( "Let's inform the user that we have seen their message...");
						socket.emit( 'send_seen_message', {
							'from': user.id,
							'to_user': account.id,
							'seen': true
						});
						return newMessages;
					});
					scrollToBottom();
				});
			}
		};

		socket.on( 'received_seen_message', handleSeenMessage );
		socket.on( 'received_chat_message', handleReceivedMessage );
		return () => {
			socket.off( 'received_seen_message', handleSeenMessage );
			socket.off( 'received_chat_message', handleReceivedMessage );
		};
	}, [account.id, messages, seen, socket, user.id]);

	useEffect(() => {
		setProcessing( true );
		Chat.UpdateSeenMessage({ 'from': account.id }).then(() => {
			Chat.AccountConversation( account.id ).then(( data ) => {
				setMessages( data.messages );
				setSeen( data.last_seen );
				socket.emit( 'send_seen_message', {
					'from': user.id,
					'to_user': account.id,
					'last_seen': data.last_seen.receiver,
					'seen': true
				});
				setProcessing( false );
			});
		});
	}, [account, socket, user.id]);

	const handleKeyPress = (e) => {
		if ( e.key === 'Enter' ) {
			const message = e.target.value.trim();
			if ( message && socket ) {
				Chat.SendMessage( account.id, message ).then(() => {
					Chat.UpdateSeenMessage({ 'from': account.id }).then(() => {
						console.log( "no need to inform the other user since i'm the one who is sending the message..." );
					});
					setMessages(prevMessages => [...prevMessages, {
						'type': 'sender',
						'message': htmlentities( message ),
						'date_sent': currentDate()
					}]);
					setSeen( prevSeen => ({
						...prevSeen,
						sender: messages.length + 2
					}));
					socket.emit( 'send_chat_message', {
						'id': account.id,
						'message': htmlentities( message )
					});
				}).catch((err) => {
					if ( err.message ) {
						Modal.show( 'chat-message-error', `<div class="px-8 py-2 bg-red-500 text-center">${err.message}</div>` )
							.setPosition( 'center-bottom' )
							.setAutoClose( 2500 );
					}
				});
				e.target.value = '';
				handleOnChange( e );
			}
		}
	};

	const handleOnChange = (event = null) => {
		const typing = event.target.value !== '';
		socket.emit( 'send_user_typing', {
			'my_id': user.id,
			'to_id': account.id,
			'typing': typing
		});
	};

	const handleCallUser = () => {
		Call.StartCall([ account.id ]).then( (room) => {
			socket.emit( 'send_request_call', {
				'room': room,
				'my_name': user.name,
				'my_id': user.id,
				'to_id': [ account.id ]
			});
			openPopup( `../groupcall/ROOM:${room}`, 900, 650 );
		}).catch(( err ) => {
			if ( err.message ) {
				Modal.show( 'chat-message-error', `<div class="px-8 py-2 bg-red-500 text-center">${err.message}</div>` )
					.setPosition( 'center-bottom' )
					.setAutoClose( 2500 );
			}
		});
	}

	return <>
		<Card classExt="flex flex-col gap-3">
			<div className="p-3 flex flex-row justify-between gap-3 border-b custom-border">
				<div className="flex flex-row gap-3">
					<img src={account.profile_picture ?? profilePic}
						 className="w-[20px] h-[20px] md:w-[45px] md:h-[45px] rounded-full object-cover border-gray-300 border-2 p-[.030rem]" alt="Cover" />
					<div className="flex flex-col gap-1 pt-0">
						<h1 className="text-[1rem] text-[--text-title] font-semibold p-0 m-0">{account.name}</h1>
						<small className="font-bold text-[--text-article] flex items-center gap-1 mt-[-.2rem]">1h <span>*</span>Online</small>
					</div>
				</div>
				<div className="flex flex-row items-center gap-2 text-[--text-title] pr-2">
					<FontAwesomeIcon icon={faPhone} className="font-extrabold p-1 rounded h-[1.1rem] w-[1.1rem] custom-hover"
									 onClick={() => handleCallUser()}  />
					<FontAwesomeIcon icon={faVideo} className="font-extrabold p-1 rounded h-[1.1rem] w-[1.1rem] custom-hover"
									 onClick={() => handleCallUser()} />
					<FontAwesomeIcon icon={faEllipsis} className="font-extrabold p-1 rounded h-[1.1rem] w-[1.1rem] custom-hover" />
				</div>
			</div>
			<div className="p-5 flex flex-col gap-2 max-h-[25rem] overflow-y-auto">
				{ processing ? (
					<div className="flex items-center justify-center">
						<div className="text-loading">
							<span>Loading...</span>
						</div>
					</div>
				) : (
					messages && (
						<>
							{ messages.map(( message, index ) => {

								let receiverPicture = account.profile_picture ?? profilePic;
								let senderPicture = user.profile_picture ?? profilePic;

								let displayTime = false;
								let displayPictureSender = false;
								let displayPictureReceiver = false;

								if ( index === messages.length - 1 )
									displayTime = true;
								else {
									let nextMessage = messages[ index + 1 ];
									if ( nextMessage.type !== message.type )
										displayTime = true;
								}

								if ( seen.receiver === ( index + 2 ) )
									displayPictureReceiver = true;

								if ( seen.sender === ( index + 2 ) )
									displayPictureSender = true;

								return (
									<div
										className={`flex flex-col ${message.type === 'sender' ? 'items-end' : 'items-start'}`}
										key={index}
									>
										{ displayTime && (
											<small className="text-gray-500 text-center pt-5 pb-2 w-full px-2">{formatTime( message.date_sent )}</small>
										)}
										<div className="bg-[#5c6677] py-2.5 px-5 rounded-xl">
											<p className="text-white" dangerouslySetInnerHTML={{ __html: message.message }} />
										</div>
										{( displayPictureReceiver || displayPictureSender ) && (
											<div className='flex flex-row gap-2 justify-end pt-2 w-full'>
												{ displayPictureReceiver && (
													<img src={receiverPicture} className='rounded-full w-[.9rem] h-[.9rem]' alt='profile'/>
												)}
												{ displayPictureSender && (
													<img src={senderPicture} className='rounded-full w-[.9rem] h-[.9rem]' alt='profile'/>
												)}
											</div>
										)}
									</div>
								);
							})}
							<div ref={messagesEndRef}/>
						</>
					)
				)}
			</div>
			<div
				className="relative p-3 border-t custom-border flex flex-row gap-3 items-center text-[--text-list-items] bg-inherit">
				{ isTyping &&
					<div className="absolute left-[.5rem] top-[-1.8rem] flex flex-row gap-1 items-center">
						<small className='text-[#A5A5A5FF]'>User is typing</small>
						<div className="typing-loading">
							<div className="typing-loading-box1"></div>
							<div className="typing-loading-box2"></div>
							<div className="typing-loading-box3"></div>
						</div>
					</div>
				}
				<div className="flex flex-row gap-1">
					<FontAwesomeIcon icon={faCirclePlus} className="h-[1.2rem] w-[1.2rem] custom-hover p-1 rounded"/>
					<FontAwesomeIcon icon={faImage} className="h-[1.2rem] w-[1.2rem] custom-hover p-1 rounded"/>
				</div>
				<input 	type="text"
					   	className="rounded-xl px-3 py-[.3rem] w-full bg-[--textarea-bg] text-[--textarea-text] focus:outline-none focus:border-transparent hover:border-transparent"
					   	placeholder="Your message here..."
					   	onKeyDown={handleKeyPress}
						onChange={handleOnChange}/>
				<div className="flex flex-row gap-1">
					<FontAwesomeIcon icon={faFaceSmile} className="h-[1.2rem] w-[1.2rem] custom-hover p-1 rounded"/>
					<FontAwesomeIcon icon={faThumbsUp} className="h-[1.2rem] w-[1.2rem] custom-hover p-1 rounded"/>
				</div>
			</div>
		</Card>
	</>;
}

export default UserChatBox;
