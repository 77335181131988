
function PageLoadingScreen({ show }) {
	
	if ( !show )
		return null;
	
	return (
		<div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-700 bg-opacity-50 z-[1001]">
			<span className="spinner"></span>
		</div>
	);
}

export default PageLoadingScreen;