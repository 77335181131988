
function Card({ children, classExt, ...rest }) {
	
	return (
		<div
			className={`relative
				rounded-md
				overflow-hidden
				bg-[--card]
				text-sm
				shadow
				text-[#e5e5e5] ${classExt}`}
			{...rest}>
			{children}
		</div>
	);
}

export default Card;
