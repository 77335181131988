import Card from "../../../usable/Card";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useState} from "react";

function ServerList() {
	
    const Servers = [
        { id: 1, title: "Philippines", onlineCount: 15, maximumMembers: 100 },
        { id: 2, title: "United States", onlineCount: 10, maximumMembers: 120 }
    ];

    const [ isOpen, setIsOpen ] = useState( Servers.length ? true : false );
    const toggleGrid = () => setIsOpen( !isOpen );

    const ServerCard = ({ id, title, onlineCount, maximumMembers }) => (
        <div className="flex flex-row justify-between gap-1 custom-hover px-4 py-2">
            <div className="flex flex-col gap-1 pt-0 whitespace-nowrap overflow-hidden overflow-ellipsis">
                <h1 className="text-[.8rem] text-[--text-article] font-semibold">{title}</h1>
                <small className="text-[--text-details] flex items-center gap-1 mt-[-.2rem]">
                    {onlineCount} Online / <span>{maximumMembers} Max.</span>
                </small>
            </div>
        </div>
    );

	return <>
		<Card classExt="flex flex-col">
            <div className="flex flex-row justify-between p-4">
                <h1 className="text-[1.5rem] text-[--text-title]">Server List</h1>
                <div className="flex flex-row gap-2 text-[#ececec] pr-2">
                    <small className="font-bold flex gap-1">
                        <FontAwesomeIcon icon={isOpen ? faMinus : faPlus} className="custom-hover rounded p-1 h-[1rem] w-[1rem]" onClick={toggleGrid} />
                    </small>
				</div>
            </div>
            {isOpen && (
				<div className="flex flex-col p-0 border-t custom-border-list">
					{Servers.map((channel, index) => (
                        <ServerCard
                            key={index}
                            id={channel.id}
                            title={channel.title}
                            onlineCount={channel.onlineCount}
                            maximumMembers={channel.maximumMembers}
                        />
                    ))}
				</div>
			)}
		</Card>
	</>;
}

export default ServerList;
