import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/free-solid-svg-icons";

const Dialog = ({ open, isLoaded = true, title, transparent = false, onClose, children }) => {

    return (
        open &&
        <div className={`transparent ${ transparent ? 'z-[1000] bg-black bg-opacity-50' : '' }`}>
            <div className={`dialog ${ open ? 'active': '' }`}>
                <div className="header">
                    <div className="title">
                        <label>{ title ? title : 'Dialog Title' }</label>
                        <div className={`loader ${ isLoaded ? 'loaded' : ''}`}>
                            <div className="box-load1"></div>
                            <div className="box-load2"></div>
                            <div className="box-load3"></div>
                        </div>
                    </div>
                    <button className="rounded-full transition duration-200 border-2 h-[40px] w-[40px] hover:bg-gray-300 hover:text-[#7b7b7c]"
                            onClick={() => onClose( false )}>
                        <FontAwesomeIcon icon={faXmark}/>
                    </button>
                </div>
                <div className={`content ${ isLoaded ? 'loaded' : ''}`}>{children}</div>
            </div>
        </div>
    );
};

export default Dialog;
