
function UserContainer({ children, classExt }) {
	
	return (
		<div className={`relative bg-[--background] h-screen ${classExt}`}>
			{children}
		</div>
	);
}

export default UserContainer;
