import Card from "../../usable/Card";
import {faClock, faHeart, faScroll} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function UserStrangerDetails({ info }) {

    const dateCreatedFormat = ( data_string ) => {
        const date = new Date( data_string );

        const options = { month: 'long', year: 'numeric' };
        return date.toLocaleDateString( 'en-US', options );
    };

    const ListItem = ({ icon, text }) => {
        return (
            <li className="flex items-center flex-row gap-3">
                <FontAwesomeIcon icon={icon} className="text-gray-400 h-[1rem] w-[1rem]" />
                <span className="text-[.9rem] text-[--text-list-items]">{text}</span>
            </li>
        );
    }

    return (
        <Card classExt="p-[1rem]">
            <h1 className="text-[1.5rem] text-[--text-title]">Intro</h1>
            <ul className="mt-5 flex flex-col gap-3">
                { info.role && <ListItem icon={faScroll} text={ info.role } /> }
                { info.relationship_status && <ListItem icon={faHeart} text={ info.relationship_status } /> }
                <ListItem icon={faClock} text={dateCreatedFormat( info.date_created )} />
            </ul>
        </Card>
    );
}

export default UserStrangerDetails;
