import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBell, faMagnifyingGlass, faMessage} from "@fortawesome/free-solid-svg-icons";
import Card from "../../usable/Card";
import React from "react";

function UserProfileTopBar() {
	
	return (
		<Card className="flex flex-col gap-3">
			<div className="flex flex-row justify-between p-3">
				<div className="flex flex-row gap-3 justify-center items-center">
					<img src="https://i.pinimg.com/736x/de/59/4e/de594ec09881da3fa66d98384a3c72ff.jpg"
						 className="
								w-[30px]
								h-[30px]
								p-[.030rem]
								rounded-full
								object-cover
								border-gray-300
								border-2
								cursor-pointer
								transition-opacity
								duration-500
								hover:opacity-90
								mt-[-.2rem]
					 		" alt="Thumbnail "/>
					<div className="flex flex-col">
						<h1 className="text-[.9rem]">Robroy Canales</h1>
						<p className="text-[#a3a3a3] text-[.7rem] max-w-[80%]">123 Followers</p>
					</div>
				</div>
				<div className="flex flex-row gap-2 justify-center items-center">
					<FontAwesomeIcon
						icon={faMagnifyingGlass}
						className="
							h-[.7rem]
							w-[.7rem]
							p-[.500rem]
							bg-gray-700
							rounded-full
							custom-hover
							md:hidden
						"/>
					<FontAwesomeIcon
						icon={faMessage}
						className="
							h-[.7rem]
							w-[.7rem]
							p-[.500rem]
							bg-gray-700
							rounded-full
							custom-hover
							md:hidden
						"/>
					<FontAwesomeIcon
						icon={faBell}
						className="
							h-[.7rem]
							w-[.7rem]
							p-[.500rem]
							bg-gray-700
							rounded-full
							custom-hover
							md:hidden
						"/>
				</div>
			</div>
		</Card>
	);
}

export default UserProfileTopBar;
