import axios from "../structure/Axios";
import {HttpRequest} from "../usable/Request";


class Chat {

    static async UpdateSeenMessage( payload ) {
        return new Promise( async ( resolve, reject ) => {
            await axios.post( `/seen-message`, {
                'from': payload.from,
                'seen': payload.type
            }).then( res => {
                resolve( res );
            });
        });
    }

    static async SendMessage( user_id, message ) {
        return new Promise( async ( resolve, reject ) => {
            let request = new HttpRequest.Request();
            let response = request.validate({
                rules: {
                    message: {
                        required: true,
                        maxLength: 255,
                        value: message
                    }
                },
                messages: {
                    message: {
                        required: 'Your message is required.'
                    }
                }
            });

            if ( response.isSuccess() ) {

                await axios.get('../sanctum/csrf-cookie');
                await axios.post( `/send-message`, {
                    'to_user_id': user_id,
                    'message': message.trim()
                }).then( (res) => {
                    resolve( res.data );
                }).catch((err) => {
                    reject({ 'message': err.message, ...err.validation });
                });

            } else {

                const errors = response.getResponse();
                const temp = {};
                Object.keys( errors ).forEach( ( key ) => {
                    temp[ key ] = errors[ key ][ Object.keys( errors[ key ] )[0] ];
                });
                reject( temp );
            }
        });
    }

    static async AccountConversation( user_id ) {
        return new Promise( async ( resolve, reject ) => {
            await axios.get( `/chat-history?id=${user_id}` ).then( (res) => {
                resolve( res.data );
            });
        });
    }
}

export { Chat };