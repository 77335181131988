import axios from "../structure/Axios";

class Users {

    static async IsConnected( user_id ) {
        return new Promise( async ( resolve, reject ) => {
            await axios.get( `/is-connected?to_user_id=${user_id}` ).then( (res) => {
                resolve( res.data );
            });
        });
    }

    static async ConnectionList() {
        return new Promise( async ( resolve, reject ) => {
            await axios.get( `/connection-list?scope=name,profile_picture` ).then( (res) => {
                resolve( res.data );
            });
        });
    }

    static async IsFollowed( user_id ) {
        return new Promise( async ( resolve, reject ) => {
            await axios.get( `/is-followed?id=${user_id}` ).then( (res) => {
                resolve( res );
            });
        });
    }

    static async PopularList() {
        return new Promise( async ( resolve, reject ) => {
            await axios.get( '/user-popular-list?scope=name,role,profile_picture&limit=3' ).then( ( res) => {
                resolve( res.data );
            }).catch( ( error ) => {
                console.error( 'Error fetching popular list:', error );
                reject( error );
            });
        });
    }

    static async Info( user_id, scopes = []) {
        return new Promise( async ( resolve, reject ) => {
            await axios.get( `/user-info?user_id=${user_id}&scope=${scopes.join(',')}` ).then( ( res) => {
                resolve( res.data );
            }).catch( ( error ) => {
                console.error( 'Error fetching popular list:', error );
                reject( error );
            });
        });
    }

    static async FollowUser( user_id ) {
        return new Promise( async ( resolve, reject ) => {
            await axios.get( '../sanctum/csrf-cookie' );
            await axios.post('/follow-user', {
                to_user_id: user_id
            }).then( ( res) => {
                resolve( res.data );
            }).catch( ( error ) => {
                reject({ 'message': error.message, ...error.validation });
            });
        });
    }

    static async UnFollowUser( user_id ) {
        return new Promise( async ( resolve, reject ) => {
            await axios.get( '../sanctum/csrf-cookie' );
            await axios.post('/unfollow-user', {
                to_user_id: user_id
            }).then( ( res) => {
                resolve( res.data );
            }).catch( ( error ) => {
                reject({ 'message': error.message, ...error.validation });
            });
        });
    }

    static async UpdateProfileInfo( payload ) {

        const formData = new FormData();
        for ( const key in payload ) {
            formData.append( key, payload[key] );
        }

        return new Promise( async ( resolve, reject ) => {
            await axios.get( '../sanctum/csrf-cookie' );
            await axios.post('/update-user', formData, {
                'Content-Type': 'multipart/form-data'
            }).then( ( res) => {
                resolve( res.data );
            }).catch( ( error ) => {
                reject({ 'message': error.message, ...error.validation });
            });
        });
    }
}

export { Users };