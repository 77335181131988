import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faComment, faEarthAsia, faEllipsis, faShare, faThumbsUp, faXmark } from "@fortawesome/free-solid-svg-icons";
import Card from "../../usable/Card";
import React from "react";

function UserPost() {
	
	return <>
			
			<Card classExt={`flex flex-col gap-5 p-[.7rem] md:px-7 md:py-5 `}>
				<div className="flex justify-between gap-3">
					<div className="flex flex-row gap-3">
						<img src="https://i.pinimg.com/736x/de/59/4e/de594ec09881da3fa66d98384a3c72ff.jpg"
							 className="w-[40px] h-[40px] md:w-[60px] md:h-[60px] rounded-full object-cover border-gray-300 border-2 p-[.030rem]" alt="Cover" />
						<div className="flex flex-col gap-1 pt-0">
							<h1 className="text-[1rem] text-[--text-title] font-semibold p-0 m-0">Robroy Canales</h1>
							<p className="text-[--text-details] p-0 m-0">Senior Product Marketing at Alfalink Corporation</p>
							<small className="font-bold text-[--text-details] flex items-center gap-1 mt-[-.2rem]">1h <span>*</span><FontAwesomeIcon icon={faEarthAsia} className="h-[.850rem] w-[.850rem] text-[--text-title]" /></small>
						</div>
					</div>
					<div className="flex flex-row gap-1 text-[--text-title]">
						<FontAwesomeIcon icon={faEllipsis} className="font-extrabold p-1 rounded h-[1.1rem] w-[1.1rem] custom-hover" />
						<FontAwesomeIcon icon={faXmark} className="font-extrabold p-1 rounded h-[1.1rem] w-[1.1rem] custom-hover" />
					</div>
				</div>
				<div className="flex flex-row">
					<p className="text-[--text-article]">Here's a peek into two of my favourite content creation features in Alfalink Corp.</p>
				</div>
				<div className="grid grid-cols-3 grid-flow-cols gap-3 p-1 md:px-4 md:py-1 md:text-[.9rem] text-[.8rem] border-t border-b custom-border text-[--text-article]">
					<div className="flex items-center gap-3 justify-center p-3 font-bold custom-hover rounded-md">
						<FontAwesomeIcon icon={faThumbsUp} className="h-[1.1rem] w-[1.1rem]" /> Like
					</div>
					<div className="flex items-center gap-3 justify-center p-3 font-bold custom-hover rounded-md">
						<FontAwesomeIcon icon={faComment} className="h-[1.1rem] w-[1.1rem]" /> Comment
					</div>
					<div className="flex items-center gap-3 justify-center p-3 font-bold custom-hover rounded-md">
						<FontAwesomeIcon icon={faShare} className="h-[1.1rem] w-[1.1rem]" /> Share
					</div>
				</div>
				<div className="flex flex-row gap-4">
					<img src="https://i.pinimg.com/736x/de/59/4e/de594ec09881da3fa66d98384a3c72ff.jpg"
						 className="w-[40px] h-[40px] rounded-full object-cover border-gray-300 border-2 p-[.030rem]" alt="Cover" />
					<textarea className="w-full max-w-full rounded-[2rem] bg-[--textarea-bg] text-[--textarea-text] items-center h-[40px] px-3 py-[.6rem]" placeholder="Write a comment..."></textarea>
				</div>
			</Card>
	</>;
}

export default UserPost;
