// AuthContainer.js
import React, { useEffect, useState } from 'react';

function AuthContainer({ children }) {
	const [isActive, setIsActive] = useState(false);
	
	useEffect(() => {
		const timer = setTimeout(() => {
			setIsActive(true);
		}, 300 );
		return () => clearTimeout(timer);
	}, []);
	
	return (
		<div className="relative h-screen">
			<div
				className={`
					relative
                    p-9
                    w-[90%]
                    md:w-[500px]
                    rounded
                    bg-[#1C2227]
                    center-absolute-animate
                    text-[#e5e5e5]
                    ${isActive ? 'active' : ''}
                `}
			>
				{children}
			</div>
		</div>
	);
}

export default AuthContainer;
