import React, { createContext, useContext, useEffect, useState } from 'react';
import io from 'socket.io-client';

const SocketContextState = createContext();

export const useSocket = () => useContext(SocketContextState);

export const SocketProvider = ({ children }) => {
    const [socket, setSocket] = useState(null);

    useEffect(() => {
        const newSocket = io( 'https://socket.peer-social.online' );
        setSocket( newSocket );

        return () => {
            newSocket.disconnect();
        };
    }, []);

    return (
        <SocketContextState.Provider value={socket}>
            {children}
        </SocketContextState.Provider>
    );
};
