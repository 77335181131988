import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {usePageContentGlobalState} from "../context/PageContentState";
import UserStrangerFeatures from "./strangerProfile/UserStrangerFeatures";
import UserStrangerMainButtons from "./strangerProfile/UserStrangerMainButtons";
import UserStrangerDetails from "./strangerProfile/UserStrangerDetails";
import UserStrangerFollowers from "./strangerProfile/UserStrangerFollowers";
import UserStrangerFollowing from "./strangerProfile/UserStrangerFollowing";
import UserStrangerMenu from "./strangerProfile/UserStrangerMenu";
import {Users} from "../model/Users";
import UserStrangerBanner from "./strangerProfile/UserStrangerBanner";

const UserStrangerProfileContent = () => {

    let { id } = useParams();
    const navigate = useNavigate();
    const { setPageContentGlobalState } = usePageContentGlobalState();
    const [ account, setAccount ] = useState({});
    const [ processing, setProcessing ] = useState(false);

    useEffect( () => {

        const extractedId = id ? id.replace( 'id:', '' ) : null;

        if ( !extractedId ) {
            setPageContentGlobalState( 'home' );
            navigate( '/' );
        }

        setProcessing( true );
        Users.Info( extractedId, [
            'name',
            'role',
            'bio',
            'relationship_status',
            'profile_picture',
            'cover_photo',
            'date_created'
        ]).then( (data) => {
            setAccount( data );
            setProcessing( false );
        }).catch(() => {
            setPageContentGlobalState( 'home' );
            navigate( '/' );
        });

    }, [id, navigate, setPageContentGlobalState]);

    if ( !account )
        return null;

    return (
        <div className="flex gap-3">
            <div className="w-full md:w-8/12 height-auto flex flex-col gap-3">
                <UserStrangerBanner info={account} processing={processing} />
                <UserStrangerMenu />
            </div>
            <div className="w-4/12 height-auto pb-1 hidden md:flex flex-col gap-3">
                <UserStrangerFeatures/>
                <UserStrangerMainButtons info={account} />
                <UserStrangerDetails info={account} />
                <UserStrangerFollowers/>
                <UserStrangerFollowing/>
            </div>
        </div>
    );
};

export {UserStrangerProfileContent};
