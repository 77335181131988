import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Navigation } from './structure/Navigation';
import { userStateContext } from './context/UserContentState';
import './Themes.css';
import './App.css';
import './Skeleton.css';
import './Dialog.css';
import './usable/Listeners';

function App() {

    const { token, user } = userStateContext();

    return (
        <Router>
            <Routes>
                { Navigation.map((route, index) => {

                    // Not authenticated
                    if ( !token ) {

                        if ( route.isPrivate )
                            return <Route key={index} path={route.path} element={<Navigate to="/login" />} />;

                    }
                    else {

                        if ( !route.isPrivate )
                            return <Route key={index} path={route.path} element={<Navigate to="/" />} />;

                        if ( route.isPrivate && user.email_verified_at === null && route.path !== "/verify-email/:email" ) {
                            const emailVerifyUrl = `/verify-email/EMAIL:${btoa(user.email)}`;
                            return <Route key={index} path={route.path} element={<Navigate to={emailVerifyUrl} />} />;
                        }
                    }

                    return (
                        <Route
                            key={index}
                            path={route.path}
                            element={
                                <React.Fragment>
                                    <TitleSetter title={route.name}>{route.element}</TitleSetter>
                                </React.Fragment>
                            }
                        />
                    );
                })}
            </Routes>
        </Router>
    );
}

function TitleSetter({ title, children }) {
    useEffect(() => {
        if (title) {
            document.title = title || 'Page Not Found';
        }
        else document.title = "Peer Social";
    }, [title]);

    return children;
}

export default App;
