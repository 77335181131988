import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import {
	faFilm, faGear,
	faHouse,
	faPeopleGroup
} from "@fortawesome/free-solid-svg-icons";

function UserMobileMenuBottom() {
	
	const GridItem = ({ icon, text }) => (
		<div className={`custom-hover rounded-xl flex flex-col items-center justify-center gap-1 p-3 font-bold`}>
			<FontAwesomeIcon icon={icon} className='h-[1rem] w-[1rem]' /> {text}
		</div>
	);
	
	return (
		<div className="
				fixed
				bottom-0
				left-0
				w-full
				h-[60px]
				md:hidden
				p-0
				z-10
				bg-[#1C2227]
				border-t
				border-gray-600
				text-sm
				text-[#e5e5e5]
				overflow-hidden
			">
			<div className="grid grid-cols-4 grid-flow-cols gap-3 text-[.7rem]">
				<GridItem icon={faHouse} text="Home" />
				<GridItem icon={faFilm} text="Reels" />
				<GridItem icon={faPeopleGroup} text="Groups" />
				<GridItem icon={faGear} text="Settings" />
			</div>
		</div>
	);
}

export default UserMobileMenuBottom;
