import Card from "../../usable/Card";
import {faClock, faHeart, faLocationPin, faScroll} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useEffect, useState} from "react";
import {Users} from "../../model/Users";
import {userStateContext} from "../../context/UserContentState";

function UserProfileDetails() {

	const { user } = userStateContext();
	const [ role, setRole ] = useState( '' );
	const [ relationship, setRelationship ] = useState( '' );

	useEffect(() => {

		Users.Info( user.id, [
			'role',
			'relationship_status'
		]).then( (data) => {
			setRole( data.role );
			setRelationship( data.relationship_status );
		});

	}, [ user ]);

	const dateCreatedFormat = ( data_string ) => {
		const date = new Date( data_string );

		const options = { month: 'long', year: 'numeric' };
		return date.toLocaleDateString( 'en-US', options );
	};

	const ListItem = ({ icon, text }) => {
		return (
			<li className="flex items-center flex-row gap-3">
				<FontAwesomeIcon icon={icon} className="text-gray-400 h-[1rem] w-[1rem]" />
				<span className="text-[.9rem] text-[--text-list-items]">{text}</span>
			</li>
		);
	}

	return (
		<Card classExt="p-[1rem]">
			<h1 className="text-[1.5rem] text-[--text-title]">Intro</h1>
			<ul className="mt-5 flex flex-col gap-3">
				{ role && <ListItem icon={faScroll} text={ role } /> }
				{ relationship && <ListItem icon={faHeart} text={ relationship } /> }
				<ListItem icon={faClock} text={dateCreatedFormat( user.date_created )} />
			</ul>
		</Card>
	);
}

export default UserProfileDetails;
