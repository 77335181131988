import React, { useState } from 'react';
import {Link, useNavigate} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons';

import Input from "../usable/Input";
import AuthContainer from "../components/AuthContainer";
import AuthSocialLogins from "../components/AuthSocialLogins";
import PageLoadingScreen from "../components/PageLoadingScreen";
import {userStateContext} from "../context/UserContentState";
import {Authentication} from "../model/Authentication";
import shortcut from '../images/shortcut.png';
import {Modal} from "../usable/Modal";

function Login() {

	const navigate = useNavigate();
	const [processing, setProcessing] = useState(false);
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [errors, setErrors] = useState({});
	const {setUser, setToken} = userStateContext();

	const handleSubmit = (e) => {
		e.preventDefault();
		setProcessing( true );
		Authentication.login( email, password ).then( ( res ) => {

			setUser( res.data.user );
			setToken( res.data.token );
			setProcessing( false );
			setErrors( {} );
			navigate( '/' );

			Modal.show( 'login-form-success', `<div class="px-8 py-2 bg-green-500 text-center">Thank you for signing in.</div>` )
				.setPosition( 'center-bottom' )
				.setAutoClose( 2500 );

		}).catch( ( error ) => {
			setErrors( error );
			setProcessing( false );

			if ( error.message ) {
				Modal.show( 'login-form-error', `<div class="px-8 py-2 bg-red-500 text-center">${error.message}</div>` )
					.setPosition( 'center-bottom' )
					.setAutoClose( 2500 );
			}
		});
	};

	return <>
		<PageLoadingScreen show={processing} />
		<AuthContainer>
			<form onSubmit={handleSubmit}>
				<div className="flex flex-row justify-between items-center gap-3">
					<h1 className="text-[2rem] text-gray-300 text-center">Sign In</h1>
					<img src={shortcut} alt="Logo" className="w-[3rem] h-[3rem] rounded-md"/>
				</div>
				<hr className="my-8" />
				<div className='flex flex-col gap-6 mt-10'>
					<Input
						type="text"
						value={email}
						placeholder="Email"
						disabled={processing}
						onChange={(e) => setEmail(e.target.value)}
						error={errors.email}
					/>
					<Input
						type="password"
						value={password}
						placeholder="Password"
						disabled={processing}
						onChange={(e) => setPassword(e.target.value)}
						error={errors.password}
					/>
				</div>
				<div className="flex flex-col gap-7 mt-2">
					<Link to="/forgot-password" className="text-sm">Forgot Password?</Link>
					<div className="flex flex-col gap-3">
						<Input type="submit" label={<>Login <FontAwesomeIcon icon={faSignInAlt}/></>}
							   disabled={processing}/>
						<span className="text-sm text-center">Not a member?
							<Link to="/register" className="text-blue-400">
								&nbsp;Signup
							</Link>
						</span>
					</div>
					<AuthSocialLogins title="Or login with"/>
				</div>
			</form>
		</AuthContainer>
	</>;
}

export default Login;