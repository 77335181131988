
import React from "react";
import UserPost from "./home/UserPost";
import UserCreatePost from "./home/UserCreatePost";
import UserProfileDetails from "./profile/UserProfileDetails";
import UserProfileFeatures from "./profile/UserProfileFeatures";
import UserProfileFollowers from "./profile/UserProfileFollowers";
import UserProfileFollowing from "./profile/UserProfileFollowing";
import UserProfileMainButtons from "./profile/UserProfileMainButtons";

function UserProfileContent() {
	
	return (
		<div className="flex gap-3">
			<div className="w-full md:w-8/12 height-auto flex flex-col gap-3 items-center">
				<div className='flex flex-col gap-3 w-full lg:w-11/12'>
					<UserCreatePost />
					<UserPost />
				</div>
			</div>
			<div className="w-4/12 height-auto pb-1 hidden md:flex flex-col gap-3">
				<UserProfileFeatures />
				<UserProfileMainButtons />
				<UserProfileDetails />
				<UserProfileFollowers />
				<UserProfileFollowing />
			</div>
		</div>
	);
}

export default UserProfileContent;
