import Card from "../../usable/Card";
import {faLock, faClock, faEye} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function UserChannelDetails() {
	
    const ListItem = ({ icon, text, description = "" }) => {
		return (
			<li className="flex flex-row gap-3">
                <FontAwesomeIcon icon={icon} className="text-[--text-article] h-[1rem] w-[1rem]" />
                <div className="flex flex-col mt-[-.1rem]">
                    <span className="text-[1.1rem] text-[--text-article]">{text}</span> 
                    <small className="text-[--text-details] opacity-80 text-[.8rem]">{description}</small>
                </div>
			</li>
		);
	}

	return (
		<Card>
			<h1 className="text-[1.8rem] text-[--text-title] p-[1rem] pb-0">About</h1>
			<ul className="mt-5 flex flex-col gap-5 border-t custom-border-list p-[1rem]">
				<ListItem icon={faLock} text="Private" description="Only members can see who's in the group and what they post." />
				<ListItem icon={faEye} text="Visible" description="Anyone can find this group." />
				<ListItem icon={faClock} text="History" description="Group created on February 11, 2019" />
			</ul>
		</Card>
	);
}

export default UserChannelDetails;
