
import React, { createContext, useContext, useState } from 'react';

const ChannelContentState = createContext();
export const useChannelGlobalState = () => useContext( ChannelContentState );
export const ChannelContentStateProvider = ({ children }) =>
{
	const [ChannelGlobalState, setChannelGlobalState] = useState( '' );
	return (
		<ChannelContentState.Provider value={{ ChannelGlobalState, setChannelGlobalState }}>
			{children}
		</ChannelContentState.Provider>
	);
};
