
import React from "react";
import UserPost from "./home/UserPost";
import UserCreatePost from "./home/UserCreatePost";
import UserChannelHome from "./channel/UserChannelHome";
import UserChannelList from "./channel/UserChannelList";
import UserChannelDetails from "./channel/UserChannelDetails";
import UserChannelActivities from "./channel/UserChannelActivities";
import UserChannelNavbar from "./channel/UserChannelNavbar";
import UserChannelMembers from "./channel/UserChannelMembers";
import UserChannelEvents from "./channel/UserChannelEvents";
import UserChannelMedia from "./channel/UserChannelMedia";
import UserChannelFiles from "./channel/UserChannelFiles";
import { useChannelNavbarGlobalState } from "../context/ChannelNavbarContentState";
import { useChannelGlobalState } from '../context/ChannelContentState';

function UserChannelContent() {
	
	const { ChannelGlobalState } = useChannelGlobalState();
	const { ChannelNavbarGlobalState } = useChannelNavbarGlobalState();

	let channelContent;
	if ( ChannelGlobalState ) {
		switch ( ChannelNavbarGlobalState ) {

			case 'files':
				channelContent = <UserChannelFiles />;
				break;

			case 'media':
				channelContent = <UserChannelMedia />;
				break;

			case 'events':
				channelContent = <UserChannelEvents />;
				break;

			case 'members':
				channelContent = <UserChannelMembers />;
				break;

			default:
				channelContent = <>
					<UserCreatePost />
					<UserPost />
				</>;
				break;
		}	
	}

	return (
		<div className="flex gap-3">
			<div className="w-full md:w-8/12 height-auto flex flex-col gap-3 items-center">
				<div className='flex flex-col gap-3 w-full lg:w-11/12'>
					{
						!ChannelGlobalState && (
							<UserChannelHome />
						)
					}
					{
						ChannelGlobalState && (<>
							<UserChannelNavbar />
							{ channelContent }
						</>)
					}
				</div>
			</div>
			<div className="w-4/12 height-auto hidden md:flex flex-col gap-3">
				<UserChannelList />
				{
					ChannelGlobalState && (<>
						<UserChannelDetails />
						<UserChannelActivities />
					</>)
				}
			</div>
		</div>
	);
}

export default UserChannelContent;
