
import Facebook from "./socialLogins/Facebook";
import Google from "./socialLogins/Google";

function AuthSocialLogins({ title }) {
	return (
		<div className="flex flex-col gap-3">
			<div className="relative h-[1.5rem] flex items-center justify-center">
				<hr className="w-full bg-gray-500 rounded border-transparent" />
				<span className="absolute px-3 bg-[#1C2227] text-center text-[.8rem] text-[#e5e5e5]">
					{title}
				</span>
			</div>
			<div className="flex flex-row gap-3">
				<Facebook />
				<Google />
			</div>
		</div>
	);
}

export default AuthSocialLogins;
