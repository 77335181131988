
import {Link, useNavigate, useParams} from 'react-router-dom';
import PageLoadingScreen from "../components/PageLoadingScreen";
import React, {useEffect, useState} from "react";
import AuthContainer from "../components/AuthContainer";
import Input from "../usable/Input";
import {Authentication} from "../model/Authentication";
import {userStateContext} from "../context/UserContentState";
import {Modal} from "../usable/Modal";

function EmailVerify() {

    let { email } = useParams();
    email = email.replace('EMAIL:', '');
    email = atob( email.replace('EMAIL:', '') );

    const navigate = useNavigate();
    const [ processing, setProcessing ] = useState(false);
    const [ code, setCode ] = useState('');
    const [ errors, setErrors ] = useState({});
    const { user, setUser, setToken } = userStateContext();

    useEffect(() => {
        if ( user.email_verified_at !== null )
            navigate( '/' );
    }, [navigate, user]);

    const handleSubmit = (e) => {
        e.preventDefault();
        setProcessing( true );

        Authentication.verifyEmail( email, code ).then( ( res ) => {

            setUser( res.data.user );
            setProcessing( false );
            setErrors({});
            navigate( '/' );

            Modal.show( 'email-verification-success', `<div class="px-8 py-2 bg-green-500 text-center">Thank you, please enjoy! :-)</div>` )
                .setPosition( 'center-bottom' )
                .setAutoClose( 2500 );

        }).catch( ( error ) => {
            setErrors( error );
            setProcessing( false );
        });
    };

    const handleLogout = (e) => {
        setProcessing( true );
        Authentication.logout().then( () => {
            setUser( null );
            setToken( null );
            navigate( '/login' );
            setProcessing( false );
        }).catch( ( error ) => {
            console.log( error );
            setProcessing( false );
        });
    };

    return <>
        <PageLoadingScreen show={processing} />
        <AuthContainer>
            <form onSubmit={handleSubmit}>
                <h1 className="text-[2rem] text-center">OTP Verification</h1>
                <div className='flex flex-col gap-6 mt-6'>
                    <small className="bg-blue-300 text-center text-gray-600 rounded p-3">
                        We've sent a verification code to your email - <b>{email}</b>
                    </small>
                    {
                        errors.message &&
                        (<div className="bg-red-400 w-100 p-2 text-center rounded text-md">
                            {errors.message}
                        </div>)
                    }
                    <Input
                        type="text"
                        value={code}
                        placeholder="Enter verification code"
                        disabled={processing}
                        onChange={(e) => setCode( e.target.value )}
                        error={errors.code}
                    />
                    <Input type="submit" label="Submit" disabled={processing} />
                    <Link to="#" className="text-center" onClick={() => handleLogout()}>Sign Out</Link>
                </div>
            </form>
        </AuthContainer>
    </>;
}

export default EmailVerify;

