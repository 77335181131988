import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { useSocket } from '../context/SocketContextState';
import { usePageContentGlobalState } from '../context/PageContentState';
import { userStateContext } from '../context/UserContentState';
import UserContainer from '../components/UserContainer';
import UserSocialsSideBar from '../components/UserSocialsSideBar';
import UserProfileSideBar from '../components/profile/UserProfileSideBar';
import UserNavbar from '../components/home/UserNavbar';
import UserCreatePost from '../components/home/UserCreatePost';
import UserMobileMenuBottom from '../components/home/UserMobileMenuBottom';
import UserProfileContent from '../components/UserProfileContent';
import UserHomeContent from '../components/UserHomeContent';
import UserMessageContent from '../components/UserMessageContent';
import UserChannelContent from '../components/UserChannelContent';
import UserNotificationsContent from '../components/UserNotificationsContent';
import PageThemes from '../components/PageThemes';
import Marketplace from '../components/socials/Marketplace';
import GlobalChatContainer from '../components/socials/globalChat/GlobalChatContainer';
import DatingContainer from '../components/socials/dating/DatingContainer';
import { ChannelNavbarContentStateProvider } from '../context/ChannelNavbarContentState';
import { ChannelContentStateProvider } from '../context/ChannelContentState';
import UserSettingContent from '../components/UserSettingContent';
import { UserStrangerProfileContent } from '../components/UserStrangerProfileContent';

function Home() {

	const navigate = useNavigate();
	const socket = useSocket();
	const { user } = userStateContext();
	const { pageContentGlobalState } = usePageContentGlobalState();
	const [ isMounted, setMount ] = useState(false);

	useEffect(() => {
		if ( !isMounted ) {

			if ( pageContentGlobalState !== 'stranger_profile' )
				navigate('/');

			if ( socket && user )
				socket.emit( 'register_user', user.id );

			setMount( true );
		}
	}, [isMounted, navigate, pageContentGlobalState, socket, user]);

	let pageContent;
	switch (pageContentGlobalState) {
		case 'profile':
			pageContent = <UserProfileContent />;
			break;
		case 'notifications':
			pageContent = <UserNotificationsContent />;
			break;
		case 'channel':
			pageContent = (
				<ChannelContentStateProvider>
					<ChannelNavbarContentStateProvider>
						<UserChannelContent />
					</ChannelNavbarContentStateProvider>
				</ChannelContentStateProvider>
			);
			break;
		case 'message':
			pageContent = <UserMessageContent />;
			break;
		case 'globalChat':
			pageContent = <GlobalChatContainer />;
			break;
		case 'dating':
			pageContent = <DatingContainer />;
			break;
		case 'marketplace':
			pageContent = <Marketplace />;
			break;
		case 'setting':
			pageContent = <UserSettingContent />;
			break;
		case 'stranger_profile':
			pageContent = <UserStrangerProfileContent />;
			break;
		default:
			pageContent = <UserHomeContent />;
			break;
	}

	return (
		<UserContainer classExt="w-full flex flex-col gap-3 p-3 md:w-auto md:flex-row">
			<div className="w-full height-auto flex flex-col gap-3 md:w-3/12">
				<UserCreatePost mobile={1} />
				<UserProfileSideBar />
				<UserSocialsSideBar />
				<PageThemes />
			</div>
			<div className="w-full height-auto flex flex-col gap-3 md:w-9/12">
				<UserNavbar />
				{pageContent}
			</div>
			<UserMobileMenuBottom />
		</UserContainer>
	);
}

export default Home;
