
import {Link, useNavigate} from "react-router-dom";
import PageLoadingScreen from "../components/PageLoadingScreen";
import React, {useState} from "react";
import AuthContainer from "../components/AuthContainer";
import Input from "../usable/Input";
import shortcut from "../images/shortcut.png";
import {Authentication} from "../model/Authentication";
import {Modal} from "../usable/Modal";

function ForgotPassword() {

    const navigate = useNavigate();
    const [ processing, setProcessing ] = useState(false);
    const [ email, setEmail ] = useState('');
    const [ errors, setErrors ] = useState({});

    const handleSubmit = (e) => {
        e.preventDefault();
        setProcessing( true );

        Authentication.sendRequestRecovery( email ).then( (res) => {

            setProcessing( false );
            navigate( '/' );

            Modal.show( 'forgot-password-success', `<div class="px-8 py-2 bg-green-500 text-center">Please check you email.</div>` )
                .setPosition( 'center-bottom' )
                .setAutoClose( 3500 );

        }).catch( ( error ) => {
            setErrors( error );
            setProcessing( false );

            if ( error.message ) {
                Modal.show( 'forgot-password-error', `<div class="px-8 py-2 bg-red-500 text-center">${error.message}</div>` )
                    .setPosition( 'center-bottom' )
                    .setAutoClose( 2500 );
            }
        });
    };

    return <>
        <PageLoadingScreen show={processing} />
        <AuthContainer>
            <form onSubmit={handleSubmit}>
                <div className="flex flex-row justify-between items-center gap-3">
                    <h1 className="text-[2rem] text-gray-300 text-center">Account Recovery</h1>
                    <img src={shortcut} alt="Logo" className="w-[3rem] h-[3rem] rounded-md"/>
                </div>
                <div className='flex flex-col gap-6 mt-6'>
                    <small className="bg-blue-300 text-gray-600 rounded p-3">
                        Please enter your email address. You will recieve a link to create a new password via Email.
                    </small>
                    <Input
                        type="text"
                        value={email}
                        disabled={processing}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="name@example.com"
                        error={errors.email}
                    />
                    <Input type="submit" label="Reset Password" disabled={processing}/>
                </div>
                <hr className="mt-8 mb-3 border border-gray-400"/>
                <div className="flex gap-5 text-gray-300 justify-end">
                    <Link to="/login">Login</Link>
                    <Link to="/register">Register</Link>
                </div>
            </form>
        </AuthContainer>
    </>;
}

export default ForgotPassword;
