import React, {useState} from "react";
import Card from "../../usable/Card";
import { useChannelNavbarGlobalState } from "../../context/ChannelNavbarContentState";

function UserChannelNavbar() {
	
	const { setChannelNavbarGlobalState } = useChannelNavbarGlobalState();
	const [ selectedItem, setSelectedItem ] = useState( 'discussion' );
	const handleItemClick = ( item ) => {
		setChannelNavbarGlobalState( item );
		setSelectedItem( item );
	};

	return <>
	
		<Card classExt="p-2 px-5 md:flex justify-between text-[2rem] hidden">
			<ul className="flex flex-row gap-2 justify-center items-center">
                 <li className="custom-hover text-[--text-title] p-2 rounded relative">
                    <b>IT Department</b>
				</li>
			</ul>
			<ul className="flex flex-row gap-3 justify-center items-center text-[--text-list-items]">
				<li className={`custom-hover p-2 rounded  ${selectedItem === 'discussion' ? 'active' : ''}`} onClick={() => handleItemClick( 'discussion' )} >Discussion</li>
				<li className={`custom-hover p-2 rounded  ${selectedItem === 'members' ? 'active' : ''}`} onClick={() => handleItemClick( 'members' )} >Members</li>
                <li className={`custom-hover p-2 rounded  ${selectedItem === 'events' ? 'active' : ''}`} onClick={() => handleItemClick( 'events' )} >Events</li>
                <li className={`custom-hover p-2 rounded  ${selectedItem === 'media' ? 'active' : ''}`} onClick={() => handleItemClick( 'media' )} >Media</li>
                <li className={`custom-hover p-2 rounded  ${selectedItem === 'files' ? 'active' : ''}`} onClick={() => handleItemClick( 'files' )} >Files</li>
			</ul>
		</Card>
	</>;
}

export default UserChannelNavbar;
