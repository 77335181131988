
import {Link, useNavigate, useParams} from "react-router-dom";
import PageLoadingScreen from "../components/PageLoadingScreen";
import React, {useEffect, useState} from "react";
import AuthContainer from "../components/AuthContainer";
import Input from "../usable/Input";
import shortcut from "../images/shortcut.png";
import {Authentication} from "../model/Authentication";
import {Modal} from "../usable/Modal";
import axios from "../structure/Axios";

function ResetPassword() {

    let { token} = useParams();
    const navigate = useNavigate();
    const [ processing, setProcessing ] = useState(false);
    const [ email, setEmail ] = useState('');
    const [ password, setPassword ] = useState('');
    const [ confirmPassword, setconfirmPassword ] = useState('');
    const [ errors, setErrors ] = useState({});

    useEffect( () => {

        const checkResetPasswordToken = async () => {

            let modifiedToken = token.replace( 'token:', '' );
            if ( !modifiedToken || modifiedToken === 'token')
                navigate( '/' );

            try {
                await axios.post('/check-reset-password-token', {
                    'token': modifiedToken
                });
            } catch (error) {
                navigate('/');
            }
        };

        checkResetPasswordToken();

    }, [ token, navigate ]);

    const handleSubmit = (e) => {
        e.preventDefault();
        setProcessing( true );
        const modifiedToken = token.replace( 'token:', '' );

        Authentication.verifyResetPassword({
            'email': email,
            'password': password,
            'password_confirmation': confirmPassword,
            'token': modifiedToken
        }).then( ( res ) => {

            setProcessing( false );
            setErrors({});
            navigate( '/' );

            Modal.show( 'reset-password-success', `<div class="px-8 py-2 bg-green-500 text-center">Password changed successfully. Please sign in again.</div>` )
                .setPosition( 'center-bottom' )
                .setAutoClose( 3500 );

        }).catch( ( error ) => {
            setErrors( error );
            setProcessing( false );

            if ( error.token ) {
                Modal.show( 'reset-password-error', `<div class="px-8 py-2 bg-red-500 text-center">Something went wrong, please try again.</div>` )
                    .setPosition( 'center-bottom' )
                    .setAutoClose( 2500 );
                navigate( '/forgot-password' );
            }

            if ( error.message ) {
                Modal.show( 'reset-password-error', `<div class="px-8 py-2 bg-red-500 text-center">${error.message}</div>` )
                    .setPosition( 'center-bottom' )
                    .setAutoClose( 3500 );
            }
        });
    };

    return <>
        <PageLoadingScreen show={processing} />
        <AuthContainer>
            <form onSubmit={handleSubmit}>
                <div className="flex flex-row justify-between items-center gap-3">
                    <h1 className="text-[2rem] text-gray-300 text-center">Reset Password</h1>
                    <img src={shortcut} alt="Logo" className="w-[3rem] h-[3rem] rounded-md"/>
                </div>
                <div className='flex flex-col gap-6 mt-6'>
                    <small className="bg-blue-300 text-center text-gray-600 rounded p-3">
                        Please verify your email and set a new password for added security.
                    </small>
                    <Input
                        type="text"
                        value={email}
                        placeholder="Email Address"
                        disabled={processing}
                        onChange={(e) => setEmail(e.target.value)}
                        error={errors.email}
                    />
                    <Input
                        type="password"
                        value={password}
                        placeholder="New Password"
                        disabled={processing}
                        onChange={(e) => setPassword(e.target.value)}
                        error={errors.password}
                    />
                    <Input
                        type="password"
                        value={confirmPassword}
                        placeholder="Confirmation Password"
                        disabled={processing}
                        onChange={(e) => setconfirmPassword(e.target.value)}
                        error={errors.confirm_password}
                    />
                    <Input type="submit" label="UPDATE" disabled={processing}/>
                </div>
                <hr className="mt-8 mb-3 border border-gray-400"/>
                <div className="flex gap-5 text-gray-300 justify-end">
                    <Link to="/login">Login</Link>
                    <Link to="/register">Register</Link>
                </div>
            </form>
        </AuthContainer>
    </>;
}

export default ResetPassword;
