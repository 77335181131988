
function Marketing() {
	
	return (
		<div>
            Marketing!
		</div>
	);
}

export default Marketing;
