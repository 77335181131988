import Card from "../../usable/Card";
import {faMinus, faPlus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";
import React, {useState} from "react";

function UserProfileFollowing() {
	
	const Users = [
		{ imageUrl: 'https://i.pinimg.com/736x/de/59/4e/de594ec09881da3fa66d98384a3c72ff.jpg', name: 'Robroy Bustillo Canales', link: '/' },
		{ imageUrl: 'https://i.pinimg.com/736x/de/59/4e/de594ec09881da3fa66d98384a3c72ff.jpg', name: 'Renz', link: '/' },
		{ imageUrl: 'https://i.pinimg.com/736x/de/59/4e/de594ec09881da3fa66d98384a3c72ff.jpg', name: 'Jovan Villaflores', link: '/' },
		{ imageUrl: 'https://i.pinimg.com/736x/de/59/4e/de594ec09881da3fa66d98384a3c72ff.jpg', name: 'Bonifacio Canales', link: '/' },
	];
	
	const [isOpen, setIsOpen] = useState(false);
	const toggleGrid = () => {
		setIsOpen(!isOpen);
	};
	let GridItem = ({ imageUrl, name, link }) => {
		return (
			<div className="overflow-hidden flex flex-col gap-1">
				<img src={imageUrl} className="object-contain w-full h-full rounded-xl" alt="Thumbnail" />
				<p className="px-1 whitespace-nowrap overflow-hidden overflow-ellipsis">
					<Link to={link} title={name} className="text-[--text-title]">{name}</Link>
				</p>
			</div>
		);
	};
	
	return (
		<Card classExt="p-[1rem] flex flex-col gap-3">
			<div className="flex flex-row justify-between">
				<div className="flex flex-row items-center gap-1">
					<h1 className="text-[1rem] text-[--text-title]">Following</h1>
					<span className="font-semibold text-[--text-article]">(<Link to="/">4213</Link>)</span>
				</div>
				<FontAwesomeIcon icon={isOpen ? faMinus : faPlus} className="custom-hover text-[--text-title] rounded p-1 h-[1rem] w-[1rem]" onClick={toggleGrid} />
			</div>
			{isOpen && (
				<div className="grid grid-cols-3 grid-flow-cols gap-3">
					{Users.map((item, index) => (
						<GridItem key={index} imageUrl={item.imageUrl} name={item.name} link={item.link} />
					))}
				</div>
			)}
		</Card>
	);
}

export default UserProfileFollowing;
