
import Card from "../../../usable/Card";

function DatingContainer() {
	
	return (
		<div className="flex flex-row gap-3">
			<Card className="dating-card rounded-md">
				<img src="https://play-lh.googleusercontent.com/UOcoq1RWlJEQrKE0StuwGAsyzQqUa6dxvy_Ifzqzvt-Yk5UA-Nz_inrMypSey-h_QNQo=w240-h480-rw"/>
				<span className="title uppercase rounded">
					FIND MATCH!
				</span>
			</Card>
		</div>
	);
}

export default DatingContainer;
