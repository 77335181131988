import Card from "../../usable/Card";
import React from "react";
import { faSquarePlus, faEarthAmericas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function UserChannelHome() {
	
	return (
		<Card classExt="flex flex-col gap-3">
			<div className="pt-3 pb-1 px-5 flex flex-row gap-3 items-center text-[1rem]">
				<label className="text-[--text-article]">Search:</label>
				<input type="text" className="text-[--text-details] bg-inherit w-full focus:outline-none focus:border-transparent hover:border-transparent" placeholder="Type here..." />
				<button className="rounded px-2 py-2 bg-blue-500 text-[.8rem] custom-hover w-[8rem]">
					Channel &nbsp;
					<FontAwesomeIcon icon={faSquarePlus} />
				</button>
			</div>
			<div className="flex flex-col border-t custom-border">
				<div className="flex flex-row justify-between items-center py-3 px-4">
					<div className="flex flex-row gap-3 whitespace-nowrap overflow-hidden overflow-ellipsis">
						<img
							src="https://i.pinimg.com/736x/de/59/4e/de594ec09881da3fa66d98384a3c72ff.jpg"
							className="w-[20px] h-[20px] md:w-[45px] md:h-[45px] rounded-xl object-cover border-gray-300 border-2 p-[.030rem]"
							alt="Cover"
						/>
						<div className="flex flex-col gap-1 pt-0">
							<div className="flex flex-row items-center gap-3">
								<h1 className="text-[.8rem] text-[--text-article] font-semibold cursor-pointer">IT Department 2</h1>
								<FontAwesomeIcon icon={faEarthAmericas} className="text-gray-100 opacity-50" />
							</div>
							<small className="font-bold text-[--text-details] flex items-center gap-1 mt-[-.2rem]">
								120 Online | <span>350 Members</span>
							</small>
						</div>
					</div>
					<button className="rounded h-[1.8rem] bg-gray-100 opacity-80 text-black hover:text-white text-[.8rem] custom-hover w-auto px-2">
						Join &nbsp;
						<FontAwesomeIcon icon={faSquarePlus} />
					</button>
				</div>
			</div>
		</Card>
	);
}

export default UserChannelHome;
