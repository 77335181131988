
import {Link} from "react-router-dom";

function NotFound() {

    return <>
        <div className="flex justify-center items-center h-screen">
            <div className="text-center">
                <h1 className="text-6xl font-bold">404</h1>
                <h2 className="text-2xl font-semibold">Page Not Found</h2>
                <p className="mt-4">The page you are looking for might have been removed, had its name changed, or is
                    temporarily unavailable.</p>
                <Link to="/"
                      className="mt-8 inline-block px-6 py-3 rounded-lg bg-blue-500 text-white font-semibold hover:bg-blue-600">Go
                    Back to Home</Link>
            </div>
        </div>
    </>;
}

export default NotFound;
