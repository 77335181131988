import Card from "../../usable/Card";
import { useChannelGlobalState } from "../../context/ChannelContentState";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useState} from "react";

function UserChannelList() {
	
    const Channels = [
        { id: 1, title: "IT Department", onlineCount: 15, memberCount: 88 },
        { id: 2, title: "Marketing", onlineCount: 10, memberCount: 60 }
    ];

    const { setChannelGlobalState } = useChannelGlobalState();
    const [ isOpen, setIsOpen ] = useState( Channels.length ? true : false );
    const toggleGrid = () => setIsOpen( !isOpen );

    const DepartmentCard = ({ id, title, onlineCount, memberCount }) => (
        <div className="flex flex-row justify-between gap-3 custom-hover p-4" onClick={() => setChannelGlobalState(id)}>
          <div className="flex flex-row gap-3 whitespace-nowrap overflow-hidden overflow-ellipsis">
            <img
              src="https://i.pinimg.com/736x/de/59/4e/de594ec09881da3fa66d98384a3c72ff.jpg"
              className="w-[20px] h-[20px] md:w-[45px] md:h-[45px] rounded-xl object-cover border-gray-300 border-2 p-[.030rem]"
              alt="Cover"
            />
            <div className="flex flex-col gap-1 pt-0">
              <h1 className="text-[.9rem] text-[--text-article] font-semibold">{title}</h1>
              <small className="font-bold text-[--text-details] flex items-center gap-1 mt-[-.2rem]">
                {onlineCount} Online | <span>{memberCount} Members</span>
              </small>
            </div>
          </div>
        </div>
    );

	return <>
		<Card classExt="flex flex-col">
        <div className="flex flex-row justify-between p-4">
            <h1 className="text-[1.5rem] text-[--text-title]">Channels</h1>
              <div className="flex flex-row gap-2 text-[#ececec] pr-2">
                <small className="font-bold flex gap-1">
                    <FontAwesomeIcon icon={isOpen ? faMinus : faPlus} className="custom-hover rounded p-1 h-[1rem] w-[1rem]" onClick={toggleGrid} />
                </small>
				      </div>
            </div>
            {isOpen && (
				<div className="flex flex-col p-0 border-t custom-border-list">
					{Channels.map((channel, index) => (
            <DepartmentCard
                key={index}
                id={channel.id}
                title={channel.title}
                onlineCount={channel.onlineCount}
                memberCount={channel.memberCount}
            />
          ))}
				</div>
			)}
		</Card>
	</>;
}

export default UserChannelList;
