import React, { useState } from "react";
import Card from "../../../usable/Card";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Members() {
    const [isOpen, setIsOpen] = useState(true);
    const toggleGrid = () => setIsOpen(!isOpen);

    const users = [
        {
            name: "Mark Anthony",
            role: "Web Designer at Alfalink Corporation",
            image: "https://i.pinimg.com/736x/de/59/4e/de594ec09881da3fa66d98384a3c72ff.jpg"
        },
        {
            name: "Vincent Jhon Bustillo",
            role: "Web Designer at Alfalink Corporation",
            image: "https://i.pinimg.com/736x/de/59/4e/de594ec09881da3fa66d98384a3c72ff.jpg"
        },
        {
            name: "Bonifacio Canales",
            role: "Web Designer at Alfalink Corporation",
            image: "https://i.pinimg.com/736x/de/59/4e/de594ec09881da3fa66d98384a3c72ff.jpg"
        }
    ];

    return (
        <Card classExt="md:flex flex-col hidden">
            <div className="flex flex-row justify-between p-4">
                <h1 className="text-[1.5rem] text-[--text-title]">Members</h1>
                <div className="flex flex-row gap-2 text-[#ececec] pr-2">
                    <small className="font-bold flex gap-1">
                        <FontAwesomeIcon icon={isOpen ? faMinus : faPlus} className="custom-hover rounded p-1 h-[1rem] w-[1rem]" onClick={toggleGrid} />
                    </small>
                </div>
            </div>
            {isOpen && users.map((user, index) => (
                <div key={index} className="border-t custom-border-list p-[.6rem] flex flex-row gap-3 items-center">
                    <img src={user.image} className="w-[30px] h-[30px] rounded-full object-cover border-gray-300 border-2 p-[.030rem]" alt="Thumbnail" />
                    <div>
                        <h1 className="font-bold text-[.8rem] text-[--text-title]">{user.name}</h1>
                        <article className="text-[.7rem] text-[--text-details]">{user.role}</article>
                    </div>
                </div>
            ))}
        </Card>
    );
}

export default Members;
