import React, { createContext, useContext, useState } from "react";

const UserContext = createContext({
    user: null,
    token: null,
    setUser: () => {},
    setToken: () => {}
});

export const UserContextProvider = ({ children }) => {
    const [ user, setUserInternal ] = useState( JSON.parse( localStorage.getItem( 'USER' ) ) || null);
    const [ token, setTokenInternal ] = useState( localStorage.getItem( 'ACCESS_TOKEN' ) );

    const setUser = ( user ) => {
        setUserInternal( user );

        if ( user )
            localStorage.setItem( 'USER', JSON.stringify( user ) );
        else
            localStorage.removeItem('USER');
    };

    const setToken = ( token ) => {
        setTokenInternal( token );

        if ( token )
            localStorage.setItem( 'ACCESS_TOKEN', token );
        else
            localStorage.removeItem( 'ACCESS_TOKEN' );
    };

    return (
        <UserContext.Provider value={{ user, token, setUser, setToken }}>
            {children}
        </UserContext.Provider>
    );
};

// eslint-disable-next-line react-hooks/rules-of-hooks
export const userStateContext = () => useContext(UserContext);

