
import React, {createContext, useContext, useEffect, useState} from 'react';

const PageContentState = createContext();
export const usePageContentGlobalState = () => useContext( PageContentState );
export const PageContentStateProvider = ({ children }) => {
	const [processing, setProcessing] = useState(true);
	const [pageContentGlobalState, _setPageContentGlobalState] = useState('');

	useEffect(() => {
		const savedPage = localStorage.getItem( 'pageContent' );
		if ( savedPage ) {
			_setPageContentGlobalState(savedPage);
			setProcessing( false );
		} else {
			setProcessing( false );
		}
	}, []);

	const setPageContentGlobalState = (page) => {
		localStorage.setItem( 'pageContent', page );
		_setPageContentGlobalState(page);
	};

	if ( processing )
		return null;

	return (
		<PageContentState.Provider value={{ pageContentGlobalState, setPageContentGlobalState }}>
			{children}
		</PageContentState.Provider>
	);
};
